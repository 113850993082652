import { Card } from "antd"
import ServerGroupForm from "./ServerGroupForm"

const ServerGroupEdit = () => {



  return (
    <div style={{ maxWidth: 550 }}>
      <Card hoverable style={{ marginBottom: 8, marginRight: 8, cursor: "initial" }}>
        <ServerGroupForm />
      </Card>
    </div>
  )
}

export default ServerGroupEdit