import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select } from "antd";
import { useContext } from "react";
import { isClassLPAR } from "src/data/Class";
import { AdditionalApiParamsContext, RuleContext, invalidHwProps } from "../Rule";
import { AllItemsContext } from "../comp/AllItems";
import { usePreviewModal } from "../comp/preview/Preview";
import { Option } from "../hook/useParentTypes";

function RegexList({ parentTypes }: { parentTypes: Option[] }) {
  const { cls, subsystem: subsys, hw_type } = useContext(AllItemsContext);
  const { preview } = useContext(AdditionalApiParamsContext);
  const { ruleIndex, parents: { regex }, filterByParents, linuxExclude } = useContext(RuleContext);
  const invalidBaseProps = invalidHwProps({ cls, hw_type, subsystem: subsys });
  const [showModal, contextHolder] = usePreviewModal(linuxExclude);
  const isLPAR = isClassLPAR(cls);

  const handlePreviewItem = (exp: string, subsystem: string) => () => showModal({
    cls,
    subsystem,
    hw_type,
    parent: true,
    strictAcl: false,
    additionalApiParams: preview?.parents?.item,
    data: {
      class: cls,
      hw_type: isLPAR ? hw_type : undefined,
      subsystem,
      regex: [exp],
      linuxExclude
    },
  });

  const handlRemove = (remove: () => void) => () => {
    remove();
    filterByParents()
  }

  return <>
    <Form.List name={[ruleIndex, 'parents', 'regex']} >
      {(regexItems, { remove }) => (
        <div className="xm-rule-filter-regex">
          {regexItems.map(({ key, name }) => (
            <span className="xm-regex-item" key={`parent-regex-list-${key}`}>
              <Input.Group
                compact
                className={invalidBaseProps || regex[name].regex.length < 2 || !regex[name].subsystem ? 'xm-disabled-input' : 'xm-enable-input'}
              >
                <Form.Item name={[name, 'regex']} rules={[{ required: true }]}>
                  <Input
                    size="small"
                    disabled={!regex[name].subsystem && isLPAR}
                    onChange={() => filterByParents()}
                    addonBefore={
                      <Form.Item name={[name, 'subsystem']} help={<></>} rules={[{ required: true }]}>
                        <Select
                          placeholder='subsystem'
                          size='small'
                          disabled={!isLPAR}
                          options={parentTypes}
                          onChange={() => filterByParents()}
                        />
                      </Form.Item>
                    }
                  />
                </Form.Item>
                <Button
                  disabled={!regex[name].subsystem && isLPAR}
                  size="small"
                  onClick={!(invalidBaseProps || regex[name].regex.length < 2 || !regex[name].subsystem) ? handlePreviewItem(regex[name].regex, regex[name].subsystem) : undefined}
                  icon={<EyeOutlined />}
                />
              </Input.Group>
              <Button
                size="small"
                className={invalidBaseProps || regex.length < 2 ? 'xm-disabled-delete' : 'hover-danger'}
                disabled={invalidBaseProps || regex.length < 2}
                type='link'
                title='Remove rule'
                icon={<DeleteOutlined onClick={handlRemove(() => remove(name))} />} />
            </span>
          ))}
        </div>
      )}
    </Form.List>
    {contextHolder}
  </>;
}

export default RegexList;