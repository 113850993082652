import { Button, Dropdown, MenuProps } from "antd";
import { Icons, Layout, ModeBarButtonAny } from "plotly.js";
import { FC } from "react";

const cmpPlty = Icons.tooltip_compare;
const compareIcon = <svg width="1em" height="1em" fill="currentColor" viewBox={`0 0 ${cmpPlty.width} ${cmpPlty.height}`}><path d={cmpPlty.path} transform={cmpPlty.transform}></path></svg>;

export const GraphBtns: FC<{ dropdownItems: MenuProps; layout: Partial<Layout>, refresh: () => void, modebarBtns: ModeBarButtonAny[][] }> =
  ({ dropdownItems, layout, refresh, modebarBtns, ...props }) => {

    return <div className={`xm-plot-btns offset-right-${modebarBtns.flatMap(group => group.length).reduce((prev, cur) => prev + cur, 0)}`}>
      {dropdownItems?.items?.length ? <Dropdown overlayClassName='xm-plot-dropdown' menu={dropdownItems}>
        <Button size='small' type='text'>...</Button>
      </Dropdown>
        : null}
      <Button type='link' size='small' icon={compareIcon} title="Compare data on hover"
        className={layout.hovermode === 'closest' ? 'inactive' : null}
        onClick={e => {
          if (layout.hovermode === 'closest') layout.hovermode = 'x';
          else layout.hovermode = 'closest';
          refresh();
        }} />
    </div>;
  }