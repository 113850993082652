import "@fontsource/roboto/latin.css";
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { onCLS, onFID, onLCP } from 'web-vitals';
import App from './App';
import './index.less';
import { store } from './redux/store';

onCLS(console.log);
onFID(console.log);
onLCP(console.log);

createRoot(document.getElementById('root')).render(<Provider store={store}><App /></Provider>);
