import { LeftOutlined } from "@ant-design/icons";
import { Item, ServerGroup } from "@dto/serverGroup.dto";
import { UniversalGroupHwInfo } from "@dto/universalGroupHwInfo.dto";
import { Spin } from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import { useEffect, useMemo, useState } from "react";
import TabsSticky from "src/component/stickyTab/TabsSticky";
import { isClassLPAR } from "src/data/Class";
import { getServerGroupsApi } from "./ServerGroupAPI";
import { ServerGroupContext } from "./ServerGroupContext";
import ServerGroupEdit from "./ServerGroupEdit";
import "./ServerGroupForm.less";
import ServerGroupTable from "./ServerGroupTable";

interface GroupProps {
    hwTypes: UniversalGroupHwInfo[],
    label: string;
}

const UniversalGroups = ({ hwTypes, label }: GroupProps) => {

    const [isFormOpen, setIsFormOpen] = useState(false);
    const [servers, setServers] = useState<Item[]>([]);
    const [serverGroups, setServerGroups] = useState<ServerGroup[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [serverGroupInEdit, setServerGroupInEdit] = useState<ServerGroup>(null);
    const [firstLoad, setFirstLoad] = useState(false);

    const context = useMemo(() => (
        {
            isFormOpen,
            setIsFormOpen,
            servers,
            setServers,
            serverGroups,
            setServerGroups,
            serverGroupInEdit,
            setServerGroupInEdit,
            hwTypes,
            label
        }
    ), [isFormOpen, setIsFormOpen, servers, setServers, serverGroups, setServerGroups, serverGroupInEdit, setServerGroupInEdit, hwTypes, label]);

    const onTabClick = (key: string) => {
        if (key === "1") {
            setIsFormOpen(false);
        }
    }

    const loadItems = async () => {
        setIsLoading(true);
        try {
            const serverGroups = await getServerGroupsApi(hwTypes.map(h => h.hwType).join(","), !isClassLPAR(hwTypes[0].class) ? hwTypes[0].class : undefined);
            setServerGroups(serverGroups);
        } catch (error) {
            console.error(error);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (firstLoad && !isFormOpen) {
            loadItems();
        }
        if (!isFormOpen) {
            setServerGroupInEdit(null);
        }
    }, [isFormOpen])

    useEffect(() => {
        loadItems();
        setFirstLoad(true);
    }, []);

    return (
        <Spin spinning={isLoading}>
            <ServerGroupContext.Provider
                value={context}
            >
                <TabsSticky
                    defaultActiveKey='1'
                    onTabClick={onTabClick}
                >
                    <TabPane
                        tab={
                            isFormOpen
                                ? <>
                                    <LeftOutlined />
                                    <span>{label} Groups</span>
                                </>
                                : label + ' Groups'
                        }
                        key="1"
                    >
                        {isFormOpen ? <ServerGroupEdit /> : <ServerGroupTable />}
                    </TabPane>
                </TabsSticky>
            </ServerGroupContext.Provider>
        </Spin>
    )
}

export default UniversalGroups;