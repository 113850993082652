import { BasicResponseDTO } from "@dto/basicResponse.dto";
import { CreateCustomGroupFolder, CustomGroupFolderResponseDTO } from "@dto/customGroup.dto";
import { Dropdown, Input, MenuProps, Modal } from "antd";
import axios, { AxiosResponse } from "axios";
import type { BaseSelectRef } from 'rc-select';
import { Link } from "react-router-dom";
import { RoutePath } from "src/data/Routes";
import { MenuNode } from "src/model/MenuNode";
import { postApi, putApi } from "src/util/apiCalls";
import { API_URL } from "../../../data/Api";
import { Log } from '../../../service/Log';
import { GLOB } from "../../../util/Glob";

enum Context {
  FOLDER = 'folder', RENAME = 'rename', DELETE = 'delete', LINK = 'link'
}

export interface ContextState {
  folders: MenuDataNode[]
};

export interface MenuDataNode extends MenuNode {
  loading?: boolean;
  children?: MenuDataNode[];
  titleText?: string;
  key: string;
  selected?: boolean;
  label?: string;
  value?: string;
}

export class CustomFolderContextMenu {

  static readonly ROOT = 'root';

  static keepOpen: boolean;
  static selectRef: BaseSelectRef;
  static updateCaller: (load?: boolean) => void;

  getState: () => Readonly<ContextState>;
  private readonly setState: (state: ContextState) => void;
  private readonly onDelete?: (item: MenuDataNode) => void;
  private readonly isNavMenu: boolean;

  constructor(getState: () => ContextState, setState: (state: ContextState) => void, onDelete?: (item: MenuDataNode) => void, isNavMenu = false) {
    this.getState = getState;
    this.setState = setState;
    this.onDelete = onDelete;
    this.isNavMenu = isNavMenu;
  }

  static equals(first: MenuDataNode, second: MenuDataNode) {
    if (first === second) {
      return true;
    } else if (first && second) {
      return first.itemId === second.itemId;
    } else if (!first) {
      return !second;
    }
    return false;
  }


  createLabel(item: MenuDataNode) {
    return <Dropdown menu={this.contextMenu(item)} trigger={['contextMenu']}><div>{item.titleText ? item.titleText : item.label}</div></Dropdown>;
  }

  private returnFocus(load?: boolean) {

    CustomFolderContextMenu.selectRef?.focus();
    CustomFolderContextMenu.keepOpen = false;
    CustomFolderContextMenu.updateCaller?.(load);

  }

  private createItemDialog(item: MenuDataNode, type: Context) {
    let name = '';
    Modal.confirm({
      title: <span>Create new {type} inside <strong>{item.titleText || item.title}</strong></span>,
      autoFocusButton: null,
      icon: null,
      zIndex: 2000,
      content: <Input autoFocus={true} placeholder="Enter new name"
        ref={r => {
          if (!r) return;
          setTimeout(() => {
            r.input.focus();
          });
        }}
        onChange={(event) => name = event.target.value.trim()}
        onPressEnter={(event) => event.currentTarget.closest('.ant-modal-body').querySelector<HTMLButtonElement>('.ant-btn-primary').click()}></Input>,
      okText: 'Create',
      onOk: (args) => {
        if (!name?.length) {
          const warn = 'Name cannot be empty!';
          Log.warn(warn);
          return Promise.reject(warn);
        }
        const parent = this.getFresh(item, this.getState().folders);
        if (parent.children?.some(mdn => mdn.titleText === name)) {
          Log.warn(`Name ${name} already exists inside ${parent.titleText}`);
          return Promise.reject();
        }
        const group_id = MenuNode.getGroupId(item);
        return postApi<CustomGroupFolderResponseDTO, CreateCustomGroupFolder>(API_URL.CUSTOM_GROUP + '/folders',
          {
            label: name,
            parent: item.itemId === CustomFolderContextMenu.ROOT || item.group_id ? null : item.itemId,
            group_id
          }).then(response => {
            const data = response.data.data;
            const mdn: MenuDataNode = {
              label: null, key: data.custom_group_folder_id, value: data.custom_group_folder_id, itemId: data.custom_group_folder_id, title: data.label, titleText: data.label,
              isLeaf: !this.isNavMenu, children: [], url: this.isNavMenu ? `${API_URL.CUSTOM_GROUP}/menu/folder/${data.custom_group_folder_id}`
                : `${API_URL.CUSTOM_GROUP}/folders/${data.custom_group_folder_id}`
            };
            mdn.title = this.createLabel(mdn);
            const fresh = this.getFresh(item, this.getState().folders);
            if (fresh) {
              if (!fresh.children?.length) {
                fresh.children = [mdn];
              } else {
                const groupIdx = fresh.children.findIndex(mdn => mdn.key === MenuNode.GROUPS_DIVIDER_KEY);
                if (groupIdx >= 0) {
                  fresh.children = [...fresh.children.slice(0, groupIdx), mdn, ...fresh.children.slice(groupIdx)];
                } else
                  fresh.children = [...fresh.children || [], mdn];
              }
              fresh.isLeaf = false;
              this.updateFolders([...this.getState().folders]);
            } else if (item.itemId === CustomFolderContextMenu.ROOT) {
              let folders = this.getState().folders;
              const groupIdx = folders.findIndex(mdn => mdn.key === MenuNode.GROUPS_DIVIDER_KEY);
              if (groupIdx >= 0) {
                folders = [...folders.slice(0, groupIdx), mdn, ...folders.slice(groupIdx)];
              } else
                folders = [...folders, mdn];
              this.updateFolders(folders);
            }

          }, reason => Log.error(`Failed to create ${type} ${name}`, reason)).finally(() => this.returnFocus());
      },
      onCancel: () => this.returnFocus()
    });
  }

  private getFresh(item: MenuDataNode, nodes: MenuDataNode[]): MenuDataNode {
    if (!item || !nodes) return;
    for (const node of nodes) {
      if (CustomFolderContextMenu.equals(item, node)) return node;
      const result = this.getFresh(item, node.children);
      if (result) return result;
    }
  }

  private filterItemRekur(item: MenuDataNode, nodes: MenuDataNode[]) {
    if (!nodes || !item)
      return nodes;
    const result = [];
    for (const node of nodes) {
      if (CustomFolderContextMenu.equals(item, node)) {
        return nodes.filter(mdn => !CustomFolderContextMenu.equals(item, mdn));
      }
      node.children = this.filterItemRekur(item, node.children);
      result.push(node);
    }
    return result;
  }

  private updateFolders(folders: MenuDataNode[]) {
    this.setState({ folders });
    GLOB.menuService.reloadCustomGroups();
  }

  private contextMenu(item: MenuDataNode): MenuProps {
    const type = Context.FOLDER;
    return {
      onClick: info => {
        switch (info.key) {
          case Context.FOLDER:
            CustomFolderContextMenu.keepOpen = true;
            this.createItemDialog(item, info.key);
            break;
          case Context.RENAME:
            CustomFolderContextMenu.keepOpen = true;
            let name = item.titleText || item.title as string;
            Modal.confirm({
              title: 'Rename ' + type,
              autoFocusButton: null,
              icon: null,
              zIndex: 2000,
              content: <Input autoFocus={true} placeholder="Enter new name" defaultValue={name}
                ref={r => {
                  if (!r) return;
                  setTimeout(() => {
                    r.input.focus();
                  });
                }}
                onChange={(event) => name = event.target.value.trim()}
                onPressEnter={(event) => event.currentTarget.closest('.ant-modal-body').querySelector<HTMLButtonElement>('.ant-btn-primary').click()}></Input>,
              okText: 'Rename',
              onOk: () => {
                if (!name?.length) {
                  Log.warn('Name cannot be empty!');
                  return Promise.reject();
                }
                const parent = this.getFresh(item, this.getState().folders);
                if (parent.children?.some(mdn => mdn.titleText === name)) {
                  Log.warn(`Name ${name} already exists inside ${parent.titleText}`);
                  return Promise.reject();
                }
                return putApi<BasicResponseDTO, CreateCustomGroupFolder>(`${API_URL.CUSTOM_GROUP}/folders/${item.itemId}`, { label: name }).then(response => {
                  const fresh = this.getFresh(item, this.getState().folders);
                  fresh.titleText = name;
                  fresh.title = this.createLabel(fresh);
                  this.updateFolders([...this.getState().folders]);
                }, reason => Log.error(`Failed to rename ${type} ${item.titleText}`, reason)).finally(() => this.returnFocus());
              },
              onCancel: () => this.returnFocus()
            });
            break;
          case Context.DELETE:
            CustomFolderContextMenu.keepOpen = true;
            Modal.confirm({
              title: 'Confirm delete',
              autoFocusButton: null,
              zIndex: 2000,
              content: <span>Are you sure to delete {type} <strong>{item.titleText || item.title}</strong> and <strong>all</strong> custom groups it contains?</span>,
              okText: 'Delete',
              onOk: () => {
                return axios.delete<AxiosResponse<BasicResponseDTO>>(`${API_URL.CUSTOM_GROUP}/folders/${item.itemId}`).then(response => {
                  const fresh = this.getFresh(item, this.getState().folders);
                  fresh.selected = false;
                  this.onDelete && this.onDelete(item);
                  this.updateFolders(this.filterItemRekur(item, this.getState().folders));
                  this.returnFocus(true);
                }, reason => {
                  Log.error(`Failed to delete ${type} ${item.titleText}`, reason);
                  this.returnFocus();
                });
              },
              onCancel: () => this.returnFocus()
            });
            break;
          case Context.LINK:
            break;
          default:
            Log.warn('Unknown context menu ' + info.key);
        }
      }, items: [
        {
          type: 'group', children: [
            { key: Context.FOLDER, disabled: this.isNavMenu && item.isLeaf, label: 'Create folder' },
            { key: Context.RENAME, disabled: this.isNavMenu && item.isLeaf || item.itemId === CustomFolderContextMenu.ROOT || !!item.group_id, label: 'Rename' },
            { key: Context.DELETE, disabled: this.isNavMenu && item.isLeaf || item.itemId === CustomFolderContextMenu.ROOT || !!item.group_id, label: 'Delete' },
            this.isNavMenu && { key: 'divider', type: 'divider' },
            this.isNavMenu && { key: Context.LINK, label: <Link to={RoutePath.SETTINGS + RoutePath.CONFIGURATION + RoutePath.SET_CG}>Configure</Link> }
          ]
        }
      ]
    };
  }
}