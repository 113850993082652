import { IMetric } from "@dto/architecture.dto";
import { METRIC_AGGREGATE, UNIT_PREFIX } from "@dto/constants/pageResponse.constants";
import { GraphBase, Metric } from "@dto/pageResponse.dto";
import { TimeseriesDTO, TimeseriesItem, TimeseriesMetadata } from "@dto/timeseriesResponse.dto";
import { AbstractGraphPart } from "@parts/graph/AbstractGraphPart";
import { Datum, Layout, PlotData } from "plotly.js";
import { DateUtil } from "src/util/DateUtil";
import { GLOB } from "src/util/Glob";
import { UnitUtil } from "src/util/UnitUtil";
import { CommonGraphProps, GraphData, GraphState, GraphType, KTM, LayoutProps } from "./GraphTypes";

/**
 * Graph start/end time by type (short/day,long/[week,month,year]) in seconds
 */
export interface GraphRangeState {
  endShort: number;
  startDay: number;

  endLong: number;
  startWeek: number;
  startMonth: number;
  startYear: number;
}

const HOVER_X_FORMAT = "%{x|%Y-%m-%d %H:%M}";
const HOVER_X_STYLED = `<span style='font-size: 9px'>${HOVER_X_FORMAT}</span>`;

function sumax(tblData: KTM[], metric: string, stacked: boolean) {
  return tblData.map(ktm => ktm.data[metric].max || 0).reduce((prev, current) => stacked ? (prev + current) : Math.max(prev, current), 0);
}

/**
 * Helpers for plotting
 */
export class GraphPlotUtil {

  /**
   * Metric name key for when there's just one
   */
  static readonly SINGLE_ITEM = 'singleItem';
  /**
   * Maximum number of metrics to show them side by side in columns, otherwise in rows
   */
  static readonly MAX_GROUP_METRICS = 2;
  /**
   * Limit value to display formatted value with SI
   */
  static readonly MIN_SI_VALUE = 9;

  private readonly box: GraphBase;
  private readonly availableMetrics: IMetric[];
  private readonly colorMap: Record<string, string>;
  private readonly graphState: GraphState;
  //private readonly data: GraphData;

  private _maxValue = 0;
  get maxValue() { return this._maxValue; }

  constructor(box: GraphBase, common: CommonGraphProps, data: GraphData, graphState: GraphState) {
    this.box = box;
    //this.data = data;
    if (data) {
      this._maxValue = data.max;
    }
    this.availableMetrics = common?.availableMetrics;
    if (common?.colorMap)
      this.colorMap = common.colorMap;
    else {
      this.colorMap = AbstractGraphPart.mapColors(box.uuids, box.metrics, box.aggregated).colorPalette;
    }
    this.graphState = graphState;
    this.compareKTM = this.compareKTM.bind(this);
  }

  private getKTMCmpVal(ktm: KTM) {
    if (this.isMetricItems())
      return this.getMetric(ktm)?.order;

    let metricMetas = Object.values(ktm.data);
    if (this.graphState.sortMetric) {
      metricMetas = [ktm.data[this.graphState.sortMetric]];
    }
    const vals = metricMetas.map((tm) => tm[this.graphState.order]);
    if (vals.every(v => v == null)) return null;
    return vals.reduce((prev, act) => prev + act, 0);
  }

  protected compareKTM(a: KTM, b: KTM) {
    if (a.isOthers()) return 1;
    if (b.isOthers()) return -1;

    const metricCmp = this.cmpKtmIfMetric(a, b);
    if (metricCmp != null) return metricCmp;

    const aVal = this.getKTMCmpVal(a);
    const bVal = this.getKTMCmpVal(b);
    if (aVal == null) return 1;
    if (bVal == null) return -1;
    return bVal - aVal;
  }

  private getMetric(ktm: KTM) {
    return this.box.metrics.find(m => m.metric === ktm.name);
  }

  cmpKtmIfMetric(a: KTM, b: KTM) {
    const am = this.getMetric(a);
    const bm = this.getMetric(b);
    if (am?.legendTop && this.isMetricItems(am)) {
      if (bm?.legendTop && this.isMetricItems(bm))
        return bm.order - am.order;
      else
        return -1;
    } else if (bm?.legendTop && this.isMetricItems(bm))
      return 1;
  }

  /**
   * Returns predicate function to find passed uid/ metricName in plotData
   * @param uid
   * @param metricName optional metric name,
   * @returns function to check plot data
   */
  static traceMatcher(uid, metricName?: string) {
    return (pd: Partial<PlotData>) => pd.meta?.uid === uid && (!metricName || metricName === GraphPlotUtil.SINGLE_ITEM || pd.legendgrouptitle.text === metricName);
  }

  private static equals(pd: Partial<PlotData>, ktm: KTM) {
    return pd.name === ktm.name && pd.meta.parent === ktm.parent?.label;
  }

  /**
   * Sort passed plot data according to current passed table data
   * @param pds plot data array
   * @param table table data array
   */
  static sortPlotByTable(pds: Partial<PlotData>[], table: KTM[]) {
    return pds.sort((a, b) => table.findIndex(ktm => GraphPlotUtil.equals(b, ktm))
      - table.findIndex(ktm => GraphPlotUtil.equals(a, ktm)));
  }

  /**
   * Reset table color square render update count
   * @param tblData
   * @returns
   */
  static resetUpdates(tblData: KTM[]) {
    return tblData.map(ktm => new KTM({ ...ktm, updateCount: 0, updated: false }));
  }

  /**
   * Decides if item 'Others' can appear
   * @param box graph base data
   * @returns true if can limit loaded items
   */
  static isOthersAllowed(box: GraphBase) {
    return true;// box.metrics.every(m => m.stacked);
  }

  /**
  * Checks if legend table contains items and metrics
  */
  isMixedMetricItem() {
    return this.box.metrics.some(m => m.legendTop);
  }

  /**
   * Decides if metrics are per table row
   * @returns true to display metrics in rows
   */
  isSingleItem() {
    return GraphPlotUtil.isSingleItem(this.box);
  }

  static isSingleItem(box: LayoutProps['box']) {
    return GraphPlotUtil.isMetricItems(box.metrics, box.aggregated) || box.metrics.length > GraphPlotUtil.MAX_GROUP_METRICS;
  }

  /**
   * Decides if timeserie items are metrics
   * @param metric optional metric to check aggregation, checks all otherwise
   * @returns true if data items are metrics
   */
  isMetricItems(metric?: Metric) {
    const metrics = metric ? [metric] : this.box.metrics;
    return GraphPlotUtil.isMetricItems(metrics, this.box.aggregated);
  }

  static isMetricItems(metrics: Metric[], aggregated: boolean) {
    return !aggregated || metrics.every(m => m.aggregate && m.aggregate !== METRIC_AGGREGATE.items);
  }

  /**
   * Prepares plot and table data
   * @param series timeseries to build data from
   * @param oldData current plot data to maintain visibility
   * @returns object with new data
   */
  updatePlotData(series: TimeseriesDTO, oldData: Partial<PlotData>[], metrics = this.box.metrics): { unitSize: string, plotData: Partial<PlotData>[], tableData: KTM[] } {
    this._maxValue = 0;
    if (this.graphState.peakMetric) metrics = [...metrics, this.box.peakMetric];

    for (const key in series.metrics) {
      if (Object.hasOwn(series.metrics, key)) {
        const ts = series.metrics[key];
        if (metrics.some(m => m.aggregate === METRIC_AGGREGATE.sum && m.metric === key)) {
          const metricMax = ts.map(ti => ti.metadata.max).reduce((prev, cur) => prev + cur, 0);
          if (metricMax > this.maxValue) this._maxValue = metricMax;
        } else {
          for (const item of ts) {
            if (item.metadata.max && this.maxValue < item.metadata.max) this._maxValue = item.metadata.max;
          }
        }
      }
    }

    const metric = metrics[0];
    const sized = metric.defaultPrefix ? {
      unitSizePrefix: metric.defaultPrefix, unitSizedShortcut: metric.defaultPrefix + metric.shortcut,
      magnitude: Math.pow(UnitUtil.getUnitBase(metric.unit), Math.floor(Object.values(UNIT_PREFIX).indexOf(metric.defaultPrefix) / 2) + 1)
    }
      : UnitUtil.sizeUnit(this.maxValue, metric.shortcut, metric.unit);

    const plotData: Partial<PlotData>[] = [];
    const tableData: Record<string, KTM> = {};

    for (const key in series.metrics) {
      if (Object.hasOwn(series.metrics, key)) {
        const serie = series.metrics[key];
        const met = metrics.find(m => m.metric === key);
        if (met.aggregate === METRIC_AGGREGATE.avg || met.aggregate === METRIC_AGGREGATE.sum) {
          plotData.push(this.plotCommon(serie, met, oldData, tableData, sized.magnitude));
        } else {
          for (const item of serie) {
            plotData.push(this.plotCommon(serie, met, oldData, tableData, sized.magnitude, item));
          }
        }
      }
    }

    const sortedTable = Object.values(tableData).sort(this.compareKTM);
    const lastTop = sortedTable.findLast(ktm => this.getMetric(ktm)?.legendTop);
    if (lastTop)
      lastTop.props = { ...lastTop.props, separator: true };

    GraphPlotUtil.sortPlotByTable(plotData, sortedTable);
    return {
      plotData, tableData: sortedTable,
      unitSize: UnitUtil.isBaseUnit(metrics[0].unit) ? metric.shortcut : sized.unitSizedShortcut
    };
  }

  private plotCommon(serie: TimeseriesItem[], metric: Metric, oldData: Partial<PlotData>[], tableData: Record<string, KTM>, magnitude: number, item?: TimeseriesItem) {
    const pd: Partial<PlotData> = {};
    const y: number[] = [];
    const x: number[] = [];

    if (item) {
      for (const key in item.timeseries) {
        const val: number = item.timeseries[key];
        y.push(val ? (metric.order < 0 ? -val : val) : val);
      }
    } else {
      if (!serie || serie.length < 1) {
        console.debug('No items in timeserie', serie);
        return pd;
      }
      for (const key in serie[0].timeseries) {
        let sum: number = null;
        for (const ti of serie) {
          if (ti.timeseries[key] === null)
            continue;
          sum += Number(ti.timeseries[key]);
        }
        y.push(metric.aggregate === METRIC_AGGREGATE.avg ? (sum === null ? null : sum / serie.length) : sum);
      }
    }

    const meta = item ? item.metadata : serie[0].metadata;
    const datumEnd = meta.end * 1000 + DateUtil.OFFSET;
    const datumStart = meta.start * 1000 + DateUtil.OFFSET;
    const datumIncrement = meta.interval * 1000 * 60;
    for (let datum = datumStart; datum <= datumEnd; datum += datumIncrement) {
      x.push(datum);
    }
    pd.x = x;
    pd.y = y;
    // scattergl fills not implemented, browsers limit number of figures on page
    pd.type = 'scatter';

    pd.line = {};
    if (this.isMetricItems(metric) && metric.color) {
      pd.line.color = metric.color;
    } else if (this.colorMap) {
      if (this.isMetricItems(metric)) {
        pd.line.color = this.colorMap[metric.metric];
        // should be just one extra color
        if (!pd.line.color) pd.line.color = GLOB.colorPalette[Object.keys(this.colorMap).length % GLOB.colorPalette.length];
      } else {
        pd.line.color = this.colorMap[meta.uuid + GraphPlotUtil.SINGLE_ITEM];
        if (!pd.line.color) pd.line.color = this.colorMap[meta.uuid + metric.metric];
      }
    }
    let uid: string;
    let name: string;
    let otherName: string;
    if (item && !this.isMetricItems(metric)) {
      uid = item.metadata.uuid;
      if (this.box.metrics.length > GraphPlotUtil.MAX_GROUP_METRICS) {
        uid += metric.metric;
        otherName = GraphPlotUtil.SINGLE_ITEM;
      } else {
        otherName = metric.metric;
        pd.legendgrouptitle = { text: otherName };
      }
      name = item.metadata.name;
    } else {
      uid = name = metric.metric;
      otherName = GraphPlotUtil.SINGLE_ITEM;
      pd.legendgrouptitle = { text: otherName };
    }
    pd.name = name;
    // let met = this.box.metrics.find(m => m.metric === metric.metric);
    // if (!met) {
    //   if (this.availableMetrics) {
    //     met = this.availableMetrics.find(m => m.metric === metric.metric) as Metric;
    //   }
    //   if (!met && this.graphState.peakMetric) {
    //     met = this.box.peakMetric;
    //   }
    //   if (!met) {
    //     Log.error('No match for metric ' + metric.metric);
    //   }
    // }
    pd.meta = { uid, itemName: item?.metadata?.name, metric: metric.label, parent: meta.parent?.label };
    this.assignHovertemplate(pd);

    if (!oldData?.length) {
      pd.visible = metric.legendOnly ? 'legendonly' : true;
    } else {
      const oldPd = oldData.find(GraphPlotUtil.traceMatcher(uid, otherName));
      if (!oldPd)
        pd.visible = oldData.every(pd => pd.visible === true);
      else
        pd.visible = oldPd.visible;
    }

    if (metric.stacked) {
      pd.stackgroup = this.isMetricItems() ? 'one' : metric.metric;
      pd.line.width = 0;
    } else if (metric.type === 'area') {
      pd.line.width = 1;
      pd.fill = 'tozeroy';
    } else {
      pd.line.width = 1;
    }
    if (metric.type === 'area' && !metric.stacked) {
      const gapsY: Datum[] = [];
      const gapsX: Datum[] = [];
      pd.y.forEach((value, index) => {
        if (value === null) {
          // start gap
          if (index > 0 && pd.y[index - 1] !== null) {
            gapsY.push(0);
            gapsX.push(pd.x[index - 1] as number + 1);
          }
          // end gap
          if (index < pd.y.length - 1 && pd.y[index + 1] !== null) {
            gapsY.push(value);
            gapsX.push(pd.x[index] as Datum);

            // area gap must be surrounded by 0 otherwise it continues to next value
            // single value spikes in stacked graph should not have this
            gapsY.push(0);
            gapsX.push(pd.x[index + 1] as number - 1);
            return;
          }
        }
        gapsY.push(value);
        gapsX.push(pd.x[index] as Datum);
      });
      pd.y = gapsY;
      pd.x = gapsX;
    }
    const isBi = this.isBiMetric(metric);
    pd.customdata = pd.y.map(y => y == null ? null : UnitUtil.formatSI(Math.abs(y as number), 2, isBi, metric));

    let ktm = tableData[uid];
    if (!ktm) {
      ktm = new KTM();
      tableData[uid] = ktm;
      if (item)
        ktm.key = metric.metric + item.metadata.name + item.metadata.uuid;
      else
        ktm.key = metric.metric;
      ktm.name = name;
      ktm.parent = meta.parent;
      ktm.metric = metric.label;
      ktm.uid = uid;
      ktm.props = this.box.properties?.reduce((prev, current) => ({ ...prev, [current.label]: current.values[uid] }), null);

      if (ktm.isOthers()) {
        this.graphState.othersCount = this.box.uuids.length - this.graphState.loadedItems;
        if (this.graphState.loadedItems !== (serie.length - 1)) {
          this.graphState.othersTooltip = this.graphState.loadedItems - serie.length + 1 + ' items removed (no data)';
        } else {
          this.graphState.othersTooltip = null;
        }
      }
    }

    // compute metadata
    const rendered: Record<string, string | number> = {};
    if (item) {
      ktm.data[otherName] = item.metadata;

      for (const key in item.metadata) {
        if (Object.hasOwn(item.metadata, key)) {
          const val = item.metadata[key];
          rendered[key] = metric === this.box.peakMetric && key === 'avg' ? null : UnitUtil.roundValue(val, magnitude, metric?.unit, metric);
        }
      }
    }
    else {
      const metadata: TimeseriesMetadata = { name: name, min: 0, avg: 0, max: 0 };
      for (const element of serie) {
        const itemMeta = element.metadata;
        metadata.avg += itemMeta.avg;
        metadata.max += itemMeta.max;
      }
      if (metric.aggregate === METRIC_AGGREGATE.avg) {
        const count = serie.length;
        metadata.avg = metadata.avg / count;
        metadata.max = metadata.max / count;
      }
      ktm.data[otherName] = metadata;

      for (const key in metadata) {
        if (Object.hasOwn(metadata, key)) {
          const val = metadata[key];
          rendered[key] = UnitUtil.roundValue(val, magnitude, metric?.unit, metric);
        }
      }
    }

    ktm.rendered[otherName] = rendered;

    return pd;
  }

  /**
   * Decides if metric has base 2 or 10 unit conversion
   * @param metric optional metric with unit to check, uses first graph metric otherwise
   * @returns true if based 2
   */
  static isBiMetric(metric: Metric, maxValue: number) {
    return UnitUtil.isBiUnit(metric.unit) && maxValue > GraphPlotUtil.MIN_SI_VALUE;
  }

  isBiMetric(metric?: Metric) {
    return GraphPlotUtil.isBiMetric(metric || this.box.metrics[0], this.maxValue);
  }

  /**
   * Set hover template to passed plot data based on hover mode and aggregation
   * @param pd
   * @param hoverMode
   */
  static assignHoverTemplate(pd: Partial<PlotData>, hoverMode: Layout['hovermode']) {
    if (hoverMode === 'closest') {
      if (pd.meta.itemName) {
        pd.hovertemplate = `<b style='color: ${pd.line?.color}'> ${pd.meta.itemName}</b> <br><br> ${pd.meta.metric}: <b>%{customdata}</b> <br> ${HOVER_X_STYLED} <extra></extra>`;
      } else {
        pd.hovertemplate = `<b style='color: ${pd.line?.color}'> ${pd.meta.metric}:</b> <b>%{customdata}</b> <br> ${HOVER_X_STYLED} <extra></extra>`;
      }
    } else {
      pd.hovertemplate = `<b style='color: ${pd.line?.color}'> ${pd.name}:</b> <b>%{customdata}</b> <extra>${HOVER_X_FORMAT}</extra>`;
    }
  }

  /**
   * @see {@link assignHoverTemplate}
   * @param pd
   */
  assignHovertemplate(pd: Partial<PlotData>) {
    GraphPlotUtil.assignHoverTemplate(pd, this.graphState.hoverMode);
  }

  static assignTickFormat(tblData: KTM[], plotData: Partial<PlotData>[], props: LayoutProps, layout: Partial<Layout>, maxValue: number) {
    if (GraphPlotUtil.isBiMetric(props.box.metrics[0], maxValue)) {
      let maxSum = 0;
      if (GraphPlotUtil.isSingleItem(props.box)) {
        const visibleData = plotData.length ? tblData.filter(ktm => plotData.find(GraphPlotUtil.traceMatcher(ktm.uid, GraphPlotUtil.SINGLE_ITEM)).visible === true) : tblData;
        maxSum = sumax(visibleData, GraphPlotUtil.SINGLE_ITEM, props.box.metrics.some(m => m.stacked));
      } else {
        for (const metric of props.box.metrics) {
          const visibleData = plotData.length ? tblData.filter(ktm => plotData.find(GraphPlotUtil.traceMatcher(ktm.uid, metric.metric)).visible === true) : tblData;
          const sum = sumax(visibleData, metric.metric, metric.stacked);
          maxSum = Math.max(maxSum, sum);
        }
      }
      if (props.box.max) {
        maxSum = Math.max(maxSum, props.box.max);
      }
      if (maxValue) maxSum = Math.max(maxValue, maxSum);
      let reducedBi = maxSum;
      let power = -1;
      while (reducedBi >= 1024) {
        power++;
        reducedBi = reducedBi / 1024;
      }

      const sig2tmp = Number(String(reducedBi * 10).substring(0, 2));
      const significant2 = props.type === GraphType.modal ? Math.max(10, sig2tmp / 2) : sig2tmp;
      const vals: number[] = [];
      const texts: string[] = [];
      let step: number;
      if (significant2 <= 12) {
        step = 0.2;
      } else if (significant2 <= 28) {
        step = 0.5
      } else if (significant2 <= 56) {
        step = 1;
      } else {// if (significant2 >= 57) {
        step = 2;
      }
      const magnitudeStep = Math.pow(10, Math.floor(Math.log10(reducedBi)));
      const dtick = step * magnitudeStep;
      const magnitude = Math.pow(1024, power + 1);
      function sig(num: number) { return dtick < 1 ? num === Math.round(num) ? num : Number(num.toFixed(1)) : num; }
      let tick = sig(Math.round(reducedBi / dtick) * dtick);
      vals.push(tick * magnitude);
      if (String(tick).length > 3) {
        texts.push(tick / 1000 + UnitUtil.getSiSymbol(power + 1, true));
      } else
        texts.push(tick + UnitUtil.getSiSymbol(power, true));
      while ((tick = sig(tick - dtick)) > 0) {
        vals.push(tick * magnitude);
        texts.push(tick + UnitUtil.getSiSymbol(power, true));
      }
      vals.push(0);
      texts.push('0');
      layout.yaxis.tickvals = vals;
      layout.yaxis.ticktext = texts;
    }
    else
      layout.yaxis.tickformat = maxValue < GraphPlotUtil.MIN_SI_VALUE || UnitUtil.isBaseUnit(props.box.metrics[0].unit) ? '.3~r' : '.3~s';

    layout.xaxis.showticklabels = !!tblData?.length;
  }

}
