import { IMetric } from "@dto/architecture.dto";
import { Modal, ModalFuncProps, Select } from "antd";
import { FC, useState } from "react";
import { UnitUtil } from "src/util/UnitUtil";

interface YaxisModalState extends ModalFuncProps {
  metric: IMetric;
  availableMetrics: IMetric[];
  onConfirm: (metric: IMetric) => Promise<void>;
  onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export const YaxisModal: FC<YaxisModalState> = ({ metric, availableMetrics, onConfirm, onCancel, ...props }) => {
  const [selectedMetric, setSelectedMetric] = useState(metric);
  const [availableMetricOptions] = useState(() => availableMetrics?.map(UnitUtil.metric2option));

  return <Modal width={400} title="Select additional Y-axis metric" destroyOnClose
    onOk={() => onConfirm(selectedMetric)}
    onCancel={(e) => {
      setSelectedMetric(metric);
      onCancel(e);
    }} {...props}>
    <Select style={{ width: '100%' }} defaultValue={selectedMetric?.metric} allowClear={true}
      options={availableMetricOptions} placeholder="Select metric for right side Y-axis"
      onChange={(value, option: { metric: IMetric }) => setSelectedMetric(option?.metric)} />
  </Modal>;
}