import React, { ErrorInfo, PropsWithChildren } from "react";
import { DataService } from "src/service/DataService";
import { Log } from "src/service/Log";
import { GLOB } from "src/util/Glob";
import { TextUtil } from "src/util/TextUtil";
import StackTrace from "stacktrace-js";

class ErrorState {
  error: { msg: string; stack?: string; } = { msg: 'Loading stack trace source maps...' };
  info?: ErrorInfo;
  failure = false;
}

/**
 * Resettable by static prop
 */
export default class ErrorBoundary extends React.Component<PropsWithChildren, ErrorState> {
  private static _getState: () => Readonly<ErrorState>;
  private static _setState: (state: Partial<ErrorState>) => void;
  constructor(props) {
    super(props);
    this.state = new ErrorState();
    ErrorBoundary._getState = () => this.state;
    ErrorBoundary._setState = this.setState.bind(this);
  }

  static getDerivedStateFromError(error): ErrorState {
    return { error: { ...error, msg: 'Please wait before reporting, mapping error stack trace source maps...' }, failure: true };
  }

  static reset() {
    if (!ErrorBoundary._getState)
      return;
    if (ErrorBoundary._getState()?.failure) {
      ErrorBoundary._setState({ failure: false });
    }
  }

  async componentDidCatch(error: Error, info: ErrorInfo) {
    if (error.message.startsWith('Failed to fetch dynamically imported module')) {
      const err = { error: { msg: error?.toString(), stack: error?.stack }, info };
      this.setState(err);
      if (GLOB.isProdEnv()) {
        Log.warn('Have you reloaded the page after Xormon upgrade?', 0);
        const status = await DataService.checkVersionReload();
        if (status === 'reload')
          return;
      } else {
        DataService.reloadUI();
        return;
      }
    }
    StackTrace.fromError(error, {}).then(frames => {
      const err = { error: { msg: error?.toString(), stack: frames.map(sf => sf.toString()).join('\n') }, info };
      Log.sendToBE({ level: 'error', log: err });
      return this.setState(err);
    },
      reason => {
        console.error('Failed to parse source map', reason);
        const err = { error: { msg: error?.toString(), stack: error?.stack }, info };
        Log.sendToBE({ level: 'error', log: { reason, err } });
        this.setState(err);
      });
  }

  render() {
    if (this.state.failure) {
      return <div id="error-page">
        <h4>Error occurred</h4>
        <p>We are sorry, something went wrong. Please send us what were you trying to do with details below
          to <a href="mailto:support@xorux.com" target="_blank">support@xorux.com</a>
        </p>
        <pre>{TextUtil.formatJSON(this.state).replaceAll('\\n', '\n')}</pre>
      </div>;
    }

    return this.props.children;
  }
}