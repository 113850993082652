import { BasicResponseDTO } from "@dto/basicResponse.dto";
import { Item, ItemsResponseDTO, ServerGroup, ServerGroupDTO, ServerGroupResponseDTO, ServerGroupsResponseDTO } from "@dto/serverGroup.dto";
import axios from "axios";
import { API_URL } from "src/data/Api";
import { postApi, putApi } from "src/util/apiCalls";

export const getLinuxItemsApi = async (hw_type?: string, _class?: string): Promise<Item[]> => {
    try {
        const response = await axios.get<ItemsResponseDTO>(API_URL.SERVER_GROUPS + "/items?hw_type=" + (hw_type ? hw_type : "linux") + (_class ? ("&class=" + _class) : ""));
        return response.data.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const getServerGroupsApi = async (hw_types?: string, _class?: string): Promise<ServerGroup[]> => {
    try {
        const response = await axios.get<ServerGroupsResponseDTO>(`${API_URL.SERVER_GROUPS}/?hw_types=${(hw_types ? hw_types : "")}${(_class ? "&class=" + _class : "")}`);
        return response.data.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const postServerGroupApi = async (server_group: ServerGroupDTO): Promise<ServerGroup> => {
    try {
        const response = await postApi<ServerGroupResponseDTO, ServerGroupDTO>(API_URL.SERVER_GROUPS, server_group);
        return response.data.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const putServerGroupApi = async (server_group: ServerGroupDTO, server_group_id: string): Promise<ServerGroup> => {
    try {
        const response = await putApi<ServerGroupResponseDTO, ServerGroupDTO>(API_URL.SERVER_GROUPS + "/" + server_group_id, server_group);
        return response.data.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const deleteServerGroupApi = async (server_group_id: string): Promise<string> => {
    try {
        const response = await axios.delete<BasicResponseDTO>(API_URL.SERVER_GROUPS + "/" + server_group_id,);
        return response.data.message;
    } catch (error) {
        console.log(error);
        throw error;
    }
}