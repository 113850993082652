import { BasicResponseDTO } from "@dto/basicResponse.dto";
import { CreateDTO, CreateResponseDTO } from "@dto/dashboard.dto";
import { Dropdown, Input, MenuProps, Modal } from "antd";
import axios, { AxiosResponse } from "axios";
import { FaRegFolder } from "react-icons/fa";
import { RxDashboard, RxGroup } from 'react-icons/rx';
import { MenuNode } from "src/model/MenuNode";
import { GLOB } from "src/util/Glob";
import { postApi, putApi } from "src/util/apiCalls";
import { API_URL } from "../../data/Api";
import { Log } from '../../service/Log';
import './DashboardPart.less';

enum Context {
  FOLDER = 'folder', DASHBOARD = 'dashboard', DEFAULT = 'default', GROUP = 'group', RENAME = 'rename', DELETE = 'delete'
}

interface ContextState {
  options: MenuDataNode[]
};

export interface MenuDataNode extends MenuNode {
  loading?: boolean;
  children?: MenuDataNode[];
  group?: boolean;
  dashboard?: boolean;
  //titleText?: string;
  key: string;
  selected?: boolean;
  value?: string;
  label?: string;
}

export class DashboardContextMenu {

  static readonly ROOT = 'root';
  static readonly NO_GROUP = 'noGroup';

  private readonly noGroup: boolean;
  private readonly icons: boolean;

  constructor(private readonly getState: () => Readonly<ContextState>,
    private readonly setState: (state: ContextState) => void,
    private readonly isDashboard: (item: MenuDataNode) => boolean,
    private readonly isGroup?: (item: MenuDataNode) => boolean,
    private readonly onDelete?: (item: MenuDataNode) => void,
    private readonly onCreateGroup?: () => void, icons = false) {
    this.noGroup = !isGroup;
    this.icons = icons;
  }

  static equals(first: MenuDataNode, second: MenuDataNode) {
    if (first === second) {
      return true;
    } else if (first && second) {
      return first.itemId === second.itemId;
    } else if (!first) {
      return !second;
    }
    return false;
  }

  createLabel(item: MenuDataNode) {
    return item.divider ? item.title : <Dropdown menu={this.contextMenu(item)} trigger={['contextMenu']}>
      <div className="xm-dashboard-dropdown">
        {this.icons ? item.dashboard ? <RxDashboard title="Dashboard" /> : item.group ? <RxGroup title="Group" /> : <FaRegFolder title="Folder" />
          : null}<span>{item.titleText ? item.titleText : item.label}</span>
      </div>
    </Dropdown>;
  }

  private createItemDialog(item: MenuDataNode, type: Context) {
    let name = '';
    Modal.confirm({
      title: <span>Create new {type} inside <strong>{item.titleText || item.title}</strong></span>,
      icon: null,
      autoFocusButton: null,
      zIndex: 2000,
      content: <Input autoFocus={true} placeholder="Enter new name"
        ref={r => {
          if (!r) return;
          setTimeout(() => {
            r.input.focus();
          });
        }}
        onChange={(event) => name = event.target.value.trim()}
        onPressEnter={(event) => event.currentTarget.closest('.ant-modal-body').querySelector<HTMLButtonElement>('.ant-btn-primary').click()}></Input>,
      okText: 'Create',
      onOk: (args) => {
        if (!name?.length) {
          Log.warn('Name cannot be empty!');
          return Promise.reject();
        }
        let parent = this.getFresh(item, this.getState().options);
        if (!parent && item.itemId === DashboardContextMenu.ROOT) {
          parent = { key: item.key, itemId: item.itemId, value: item.itemId, children: this.getState().options, label: item.titleText, titleText: item.titleText };
        }
        if (parent?.children?.some(mdn => mdn.titleText === name)) {
          Log.warn(`Name ${name} already exists inside ${parent.titleText}`);
          return Promise.reject();
        }
        const group_id = MenuNode.getGroupId(item);
        return postApi<CreateResponseDTO, CreateDTO>(API_URL.DASHBOARD + '/' + type, {
          label: name,
          parent: item.itemId === DashboardContextMenu.ROOT || item.group_id ? null : item.itemId,
          group_id
        }).then(response => {
          if (!this.isDashboard(item) || !this.noGroup || group_id) {
            const data = response.data.data;
            const mdn: MenuDataNode = {
              label: null, key: data.id, value: data.id, itemId: data.id, dashboard: type === Context.DASHBOARD, title: data.label, titleText: data.label,
              group: type === Context.GROUP, isLeaf: type === Context.GROUP || type === Context.DASHBOARD && this.noGroup, url: `${API_URL.DASHBOARD_MENU}/${type}/${data.id}`,
              parent: item
            };
            mdn.title = this.createLabel(mdn);
            const fresh = this.getFresh(item, this.getState().options);
            if (fresh) {
              if (!fresh.isLeaf) {
                if (!fresh.children?.length || fresh.children[0].itemId === DashboardContextMenu.NO_GROUP) {
                  fresh.children = [mdn];
                } else {
                  const groupIdx = fresh.children.findIndex(mdn => mdn.key === MenuNode.GROUPS_DIVIDER_KEY);
                  if (groupIdx >= 0) {
                    fresh.children = [...fresh.children.slice(0, groupIdx), mdn, ...fresh.children.slice(groupIdx)];
                  } else
                    fresh.children = [...fresh.children || [], mdn];
                }
                this.setState({ options: [...this.getState().options] });
              }
            } else if (item.itemId === DashboardContextMenu.ROOT) {
              let opts = this.getState().options;
              const groupIdx = opts.findIndex(mdn => mdn.key === MenuNode.GROUPS_DIVIDER_KEY);
              if (groupIdx >= 0) {
                opts = [...opts.slice(0, groupIdx), mdn, ...opts.slice(groupIdx)];
              } else
                opts = [...opts, mdn];
              this.setState({ options: opts });
            }
            GLOB.menuService.reloadDashboards();
          }
          if (type === Context.GROUP)
            this.onCreateGroup?.();
        }, reason => Log.error(`Failed to create ${type} ${name}`, reason));
      }
    });
  }

  private getFresh(item: MenuDataNode, nodes: MenuDataNode[]): MenuDataNode {
    if (!item || !nodes) return;
    for (const node of nodes) {
      if (DashboardContextMenu.equals(item, node)) return node;
      const result = this.getFresh(item, node.children);
      if (result) return result;
    }
  }

  private filterItemRekur(item: MenuDataNode, nodes: MenuDataNode[]) {
    if (!nodes || !item)
      return nodes;
    const result: MenuDataNode[] = [];
    for (const node of nodes) {
      if (DashboardContextMenu.equals(item, node)) {
        return nodes.filter(mdn => !DashboardContextMenu.equals(item, mdn));
      }
      node.children = this.filterItemRekur(item, node.children);
      result.push(node);
    }
    return result;
  }

  private removeStyle(nodes: MenuDataNode[]) {
    if (!nodes) return;
    for (const node of nodes) {
      if (node.styleClass === 'star')
        node.styleClass = null;
      this.removeStyle(node.children);
    }
  }

  private contextMenu(item: MenuDataNode): MenuProps {
    const type = this.isDashboard(item) ? Context.DASHBOARD : this.isGroup?.(item) ? Context.GROUP : Context.FOLDER;
    return {
      onClick: info => {
        switch (info.key) {
          case Context.FOLDER:
            this.createItemDialog(item, info.key);
            break;
          case Context.DASHBOARD:
            this.createItemDialog(item, info.key);
            break;
          case Context.GROUP:
            this.createItemDialog(item, info.key);
            break;
          case Context.DEFAULT:
            putApi(`${API_URL.DASHBOARD}/${Context.DASHBOARD}/${item.itemId}/default`).then(() => {
              Log.success(item.titleText + ' is now default dashboard.');
              const state = this.getState().options;
              this.removeStyle(state);
              item.styleClass = "star";
              this.setState({ options: state });
            }, reason => Log.error(`Failed to make ${item.titleText} default dashboard!`, reason));
            break;
          case Context.RENAME:
            let name = item.titleText || item.title as string;
            Modal.confirm({
              title: 'Rename ' + type,
              autoFocusButton: null,
              icon: null,
              zIndex: 2000,
              content: <Input autoFocus={true} placeholder="Enter new name" defaultValue={name}
                ref={r => {
                  if (!r) return;
                  setTimeout(() => {
                    r.input.focus();
                  });
                }}
                onChange={(event) => name = event.target.value.trim()}
                onPressEnter={(event) => event.currentTarget.closest('.ant-modal-body').querySelector<HTMLButtonElement>('.ant-btn-primary').click()}></Input>,
              okText: 'Rename',
              onOk: () => {
                if (!name?.length) {
                  Log.warn('Name cannot be empty!');
                  return Promise.reject();
                }
                const parent = this.getFresh(item, this.getState().options);
                if (parent.children?.some(mdn => mdn.titleText === name)) {
                  Log.warn(`Name ${name} already exists inside ${parent.titleText}`);
                  return Promise.reject();
                }
                return putApi(`${API_URL.DASHBOARD}/${type}/${item.itemId}`, { label: name }).then(response => {
                  const fresh = this.getFresh(item, this.getState().options);
                  fresh.titleText = name;
                  fresh.title = this.createLabel(fresh);
                  this.setState({ options: [...this.getState().options] });
                  GLOB.menuService.reloadDashboards();
                }, reason => Log.error(`Failed to rename ${type} ${item.titleText}`, reason));
              }
            });
            break;
          case Context.DELETE:
            Modal.confirm({
              title: 'Confirm delete',
              autoFocusButton: null,
              zIndex: 2000,
              content: <span>Are you sure to delete {type} <strong>{item.titleText || item.title}</strong>?</span>,
              okText: 'Delete',
              onOk: () => {
                return axios.delete<AxiosResponse<BasicResponseDTO>>(`${API_URL.DASHBOARD}/${type}/${item.itemId}`).then(response => {
                  const fresh = this.getFresh(item, this.getState().options);
                  fresh.selected = false;
                  this.onDelete?.(item);
                  this.setState({ options: [...this.getState().options] });
                  this.setState({ options: this.filterItemRekur(item, this.getState().options) });
                  GLOB.menuService.reloadDashboards();
                }, reason => Log.error(`Failed to delete ${type} ${item.titleText}`, reason));
              }
            });
            break;
          default:
            Log.warn('Unknown context menu ' + info.key);
        }
      }, items: [
        {
          key: 'groupMenu', label: `${this.isDashboard(item) ? 'Dashboard' : this.isGroup?.(item) ? 'Group' : 'Folder'} ${item.titleText}`, children: [
            { key: Context.FOLDER, disabled: this.isDashboard(item) || this.isGroup?.(item), label: 'Create folder' },
            { key: Context.DASHBOARD, disabled: this.isDashboard(item) || this.isGroup?.(item), label: 'Create dashboard' },
            { key: Context.DEFAULT, disabled: !this.isDashboard(item), label: 'Make default', title: 'Display this dashboard in initial navigation.' },
            { key: Context.GROUP, disabled: !this.isDashboard(item), label: 'Create group' },
            { key: Context.RENAME, disabled: item.itemId === DashboardContextMenu.ROOT || !!item.group_id, label: 'Rename' },
            { key: Context.DELETE, disabled: item.itemId === DashboardContextMenu.ROOT || !!item.group_id, label: 'Delete' }
          ]
        }
      ]
    };
  }
}