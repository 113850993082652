import { BasicResponseDTO } from "@dto/basicResponse.dto";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

export async function postApi<ResponseData extends BasicResponseDTO, RequestData = unknown>(url: string, data?: RequestData, config?: AxiosRequestConfig<RequestData>) {
  return axios.post<ResponseData, AxiosResponse<ResponseData, RequestData>, RequestData>(url, data, config);
}

export async function putApi<ResponseData extends BasicResponseDTO, RequestData>(url: string, data?: RequestData, config?: AxiosRequestConfig<RequestData>) {
  return axios.put<ResponseData, AxiosResponse<ResponseData, RequestData>, RequestData>(url, data, config);
}

export function getApi<ResponseData extends BasicResponseDTO>(url: string, signal: AbortSignal, config?: AxiosRequestConfig) {
  return axios.get<ResponseData>(url, { signal, ...config });
}