import { CHART_TYPE, GRAPH_INTERVAL } from "@dto/constants/pageResponse.constants";
import { Table } from "antd";
import Plotly, { PlotData } from "plotly.js";
import { FC, useEffect, useState } from "react";
import { Graph } from "src/component/graph/Graph";
import { GraphType } from "src/component/graph/GraphTypes";
import { ColProps, GridScope } from "src/component/grid/Grid";
import { GLOB } from "src/util/Glob";

const Plot: FC = () => {

  const [data] = useState<Partial<PlotData>[]>([
    //   {

    //   x: [1, 2, 3, 4],

    //   y: [10, 15, 13, 17],

    //   type: 'scatter'

    // },
    {

      x: [new Date('2023-03-22T00:00').getTime(), new Date('2023-03-22T01:00').getTime(), new Date('2023-03-22T02:00').getTime(), new Date('2023-03-22T03:00').getTime()],

      //y: [16, 5, 11, 9],
      y: [.40000000, 5, .3, .4],

      // po 1: 3, 4, 5, ... 29, 55, 56x ....  >=29 && <56
      // po 2: 1, 6, 7, 8, 9, .... 10, 11, 57 ....  >=10x && <=11 || >=57
      // po 5: 2, ... 12, 13, 14+1, 15, 16, 17, 18, 19+1, 20, 21, 22, 23, 24+1, 25, 26, 27, 28x ....  >11 && <=28



      type: 'scatter'

    }]);

  useEffect(() => {


    const layout = {
      title: 'Global VMware',
      // annotations: [
      //   {
      //     font: {
      //       size: 20
      //     },
      //     showarrow: false,
      //     text: 'CPU',
      //     x: 0.17,
      //     y: 0.5
      //   },
      //   {
      //     font: {
      //       size: 20
      //     },
      //     showarrow: false,
      //     text: ' MEM',
      //     x: 0.82,
      //     y: 0.5
      //   }
      // ],
      height: 400,
      width: 600,
      //showlegend: false,
      grid: { rows: 1, columns: 2 }
    };

    void Plotly.newPlot('plotly-test', data, layout, { modeBarButtons: [['hoverClosestCartesian', 'hoverCompareCartesian']], displayModeBar: true });

  }, []);

  const [gs] = useState(() => new GridScope<{ a, b, c }>());
  const [columns] = useState<ColProps<{ a, b, c }>[]>([
    {
      title: 'ab',
      children: [
        {
          title: 'a',
          defaultSortOrder: 'ascend',
          sorter: (a, b) => GLOB.naturalSort(a.a, b.a),
          filter: { field: ['a'], type: 'number' },
          filters: [
            {
              text: 'London',
              value: 0,
            },
            {
              text: 'New York',
              value: 9,
            },
          ],
          onFilter: (value, record) => record.a > value,
          dataIndex: 'a',
          render: (value, record, index) => '-' + value
        },
        {
          title: 'b',
          filter: { field: ['b'], type: 'number' },
          sorter: (a, b) => GLOB.naturalSort(a.b, b.b),
          dataIndex: 'b'
        }
      ],
      filter: { field: ['a'], type: 'number' }
    },
    {
      title: 'c',
      filter: { field: ['c'], type: 'number' },
      dataIndex: 'c'
    },
  ]);
  const [tdata] = useState<{ key, a, b, c }[]>([{ key: 1, a: 1, b: 2, c: 3 }, { key: 2, a: '', b: null, c: 2 }, { key: 3, a: 3, b: 4, c: 5 }]);

  return <div>

    <gs.Grid columns={columns} dataSource={tdata}>

    </gs.Grid>

    <Table dataSource={tdata} columns={columns}></Table>

    <gs.Grid className="xm-table-fit" columns={columns} dataSource={[]} />

    <div id="plotly-test">;gsT</div>
    <Graph box={{ metrics: [{ metric: 'memory', label: 'memory', order: 0, stacked: false, type: CHART_TYPE.line, unit: 'bytes', shortcut: 'mem' }], uuids: [null], }}
      interval={GRAPH_INTERVAL.day} end={Date.now() / 1000} start={new Date('2023-03-22').getTime() / 1000} type={GraphType.regular}
      state={{ unitSize: '', additionalYaxis: null, hoverMode: false, loadedItems: 2, order: "avg", othersCount: 2, othersTooltip: 'tip', plotlyRef: null, tableRowKeys: [], uuids: [] }}
      data={{
        max: data.flatMap(pd => (pd.y as number[])).reduce((prev, cur) => Math.max(prev, cur), 0),
        zoomTitle: null,
        data: data,
        tableData: [
          // new KTM({
          //   data: { [GraphPlotUtil.SINGLE_ITEM]: { name: 'metaName', metric: 'memory', max: (data[0].y as number[]).reduce((prev, cur) => Math.max(prev, cur)) } },
          //   key: GraphPlotUtil.SINGLE_ITEM,
          //   name: 'ktmName',
          //   rendered: {},
          //   uid: null,
          //   updateCount: 0,
          //   updated: false,

          // })
        ]

      }} />

    <Graph box={{ metrics: [{ metric: 'memory', label: 'memory', order: 0, stacked: false, type: CHART_TYPE.line, unit: 'bytes', shortcut: 'mem' }], properties: [], uuids: [null] }}
      interval={GRAPH_INTERVAL.day} end={Date.now() / 1000} start={new Date('2023-03-22').getTime() / 1000} title="test" type={GraphType.regular}
      state={{ unitSize: '', additionalYaxis: null, hoverMode: false, loadedItems: 2, order: "avg", othersCount: 2, othersTooltip: 'tip', plotlyRef: null, tableRowKeys: [], uuids: [] }}
      data={{
        max: 1,
        zoomTitle: null,
        data: [],// data as Partial<Plotly.PlotData>[],
        tableData: [],

      }}
    />
  </div>
}

export default Plot;