import { Tabs, TabsProps } from "antd"
import { FC } from "react"
import './TabSticky.less'

const TabsSticky: FC<TabsProps> = ({ children, className, ...props }) => {
  return (
    <Tabs className={"xm-tab-sticky " + className} {...props}>
      {children}
    </Tabs>
  )
}

export default TabsSticky
