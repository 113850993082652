import { CloseOutlined } from "@ant-design/icons"
import { BasicResponseDTO } from "@dto/basicResponse.dto"
import { RecentlySearchedResponseDTO } from "@dto/recentlySearched.dto"
import { DirectoryTreeProps } from "antd/es/tree"
import DirectoryTree from "antd/lib/tree/DirectoryTree"
import axios, { AxiosError } from "axios"
import { FC, useEffect, useMemo, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { Action, useSearchReducer } from "src/menu/reducer/useSearchReducer"
import { MenuNode } from "src/model/MenuNode"
import { Log } from "src/service/Log"
import { postApi } from "src/util/apiCalls"
import "./History.less"

interface MenuDataNode extends MenuNode {
  date: Date
}

interface Props {
  tree: MenuNode
  historyState: number
  userId: number
}

function useQuery(): [string, URLSearchParams] {
  const { pathname, search } = useLocation()
  return useMemo(() => [pathname, new URLSearchParams(search)], [search])
}

const HistorySearch: FC<Props> = ({ tree, historyState, userId }) => {
  const navigate = useNavigate()

  const [path, query] = useQuery()

  const searchTitle = query.has("find") && query.get("find")

  const { dispatch } = useSearchReducer()

  const [historyData, setHistoryData] = useState<MenuDataNode[]>([])

  useEffect(() => {
    if (userId && historyState) {
      void getDataHistory()
    }
  }, [tree, historyState, userId])

  const getDataHistory = async () => {
    try {
      const response = await axios.get<RecentlySearchedResponseDTO>(
        `/api/search/v1/recently_searched?user_id=${userId}&limit=${historyState}`
      )
      const { data } = response.data
      setHistoryData(
        data
          .map((obj) => ({
            key: obj.search_query,
            path: obj.search_query,
            title: obj.search_query,
            titleText: obj.search_query,
            isLeaf: true,
            date: new Date(obj.updatedAt),
          }))
          .sort((a, b) => {
            return b.date.getTime() - a.date.getTime()
          })
      )
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError<BasicResponseDTO>
        Log.error(axiosError.response.data.message, axiosError)
      } else {
        Log.error("API Error", error)
      }
    }
  }

  const handleHistory: DirectoryTreeProps['onClick'] = async (event, node) => {
    const inputSearch = node.title as string;
    const inputEncoded = encodeURIComponent(inputSearch);
    const target = event.target as HTMLElement
    if (
      target.className === "ant-tree-node-content-wrapper ant-tree-node-content-wrapper-normal" ||
      target.className === "ant-tree-title"
    ) {
      try {
        await postApi(
          `/api/search/v1/recently_searched/add?user_id=${userId}&search_query=${inputEncoded}`
        )

        navigate(`search?find=${inputEncoded}`)
        dispatch({ type: Action.type, payload: inputSearch })
        dispatch({ type: Action.redirect })
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError<BasicResponseDTO>
          Log.error(axiosError.response.data.message, axiosError)
        } else {
          Log.error("API Error", error)
        }
      }
    }
  }

  const onDeleteHistory = async (id: string, indx: number) => {
    try {
      await postApi(
        `/api/search/v1/recently_searched/delete?user_id=${userId}&search_query=${encodeURIComponent(
          id
        )}`
      )
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError<BasicResponseDTO>
        Log.error(axiosError.response.data.message, axiosError)
      } else {
        Log.error("API Error", error)
      }
    } finally {
      await getDataHistory()
      if (historyData?.length > 1) {
        navigate(
          `search?find=${indx === 0 ? historyData[1]?.titleText : historyData[0]?.titleText}`
        )
      } else {
        navigate("search")
      }
    }
  }

  return (
    <>
      {historyData.length > 0 && (
        <>
          <h3>History</h3>
          <DirectoryTree
            className='left-tree-history-delete'
            selectedKeys={[searchTitle]}
            defaultExpandParent={true}
            showIcon={true}
            showLine={false}
            onClick={handleHistory}
            treeData={
              historyData?.length
                ? historyData.map((obj, indx) => ({
                  ...obj,
                  icon: (
                    <CloseOutlined
                      style={{ fontSize: 8 }}
                      onClick={() => onDeleteHistory(obj.titleText, indx)}
                    />
                  ),
                }))
                : []
            }
          />
        </>
      )}
    </>
  )
}

export default HistorySearch
