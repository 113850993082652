import { Item, ItemGroupPreview, ItemsResponseDTO } from "@dto/architecture.dto";
import { AxiosResponse } from "axios";
import { PropsWithChildren, createContext } from "react";
import useGetAllItemsRegex from "../hook/useGetAllItemsRegex";
import { ApiPrams } from "../Rule";
export type HwProps = Record<'cls' | 'subsystem' | 'hw_type', string>;

type IAllItemsContext = HwProps & {
  allItems: Item[];
  isLoadingAll: boolean;
  promise: Promise<AxiosResponse<ItemsResponseDTO, ItemGroupPreview>>;
  noTagMode: boolean;
}

export const AllItemsContext = createContext<IAllItemsContext>(undefined);

type Props = PropsWithChildren & HwProps & Required<Pick<ItemGroupPreview, 'linuxExclude'>> & {
  noTagMode?: boolean;
  additionalApiParams?: ApiPrams;
};

function AllItems({ cls, hw_type, subsystem, noTagMode, children, linuxExclude, additionalApiParams }: Props) {
  const [allItems, isLoadingAll, promise] = useGetAllItemsRegex(cls, hw_type, subsystem, linuxExclude, additionalApiParams);

  return (
    <AllItemsContext.Provider value={{ cls, hw_type, subsystem, allItems, isLoadingAll, promise, noTagMode }}>
      {children}
    </AllItemsContext.Provider>
  );
}

export default AllItems;