// import useArrayState from 'src/hook/useArrayState';
import { STATUS } from '@const/healthStatus.constants';
import { Status, StatusHostCfgBase } from '@dto/healthStatus.dto';
import { WebsocketReload } from '@dto/websocketMessage.dto';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ColProps, GridScope, IGridEntry } from 'src/component/grid/Grid';
import { DateUtil } from 'src/util/DateUtil';
import { GLOB } from 'src/util/Glob';
import StateInfo from './StateInfo';
import useStateData from './hook/useStateData';

interface Props {
  type: Status,
  url: string;
  wsData: WebsocketReload;
}

const StateTable = ({ type, url, wsData }: Props) => {
  const [state, loading] = useStateData(type, url, [wsData]);
  const [gs] = useState(() => new GridScope<StatusHostCfgBase>());

  const handleCloseDrawer = () => GLOB.setState({ isHSOpen: false });

  const columns: ColProps<IGridEntry & StatusHostCfgBase>[] = [
    {
      title: "Name",
      key: "name",
      minWidth: "24em",
      sorter: (a, b) => a.label.localeCompare(b.label),
      render: ({ url, label }: StatusHostCfgBase) => <Link to={GLOB.menuService.getRouteLink(url)} onClick={handleCloseDrawer}>{label}</Link>
    },
    {
      title: "HW Type",
      dataIndex: "hw_type_label",
      key: "hw_type",
      minWidth: "19em",
      sorter: (a, b) => a.hw_type.localeCompare(b.hw_type)
    },
    {
      title: "Class",
      dataIndex: "class",
      key: "class",
      minWidth: "8em",
      sorter: (a, b) => a.class.localeCompare(b.class)
    },
    {
      title: "Last Update",
      key: "last_update",
      minWidth: "12em",
      className: 'last-update',
      sorter: (a, b) => a.updatedAt - b.updatedAt,
      render: ({ updatedAt }: StatusHostCfgBase) => <>{updatedAt ? DateUtil.getDateISO(updatedAt) : ""}</>
    },
    {
      title: "Detail",
      key: "detail",
      minWidth: "4em",
      className: 'detail-cell',
      render: (record: StatusHostCfgBase) => <StateInfo title={`${record.label} (${record.hw_type_label})`} info={record.note} reason={type == 'ignored' ? record.ignore_reason : null} />
    }
  ];

  return <>
    <gs.Grid
      key={`${type}-table`}
      className="xm-table-fit"
      renderProps={{ noClipboard: true }}
      loading={loading}
      columns={columns}
      dataSource={state.map(device => ({ ...device, key: device.item_id })).sort((a: StatusHostCfgBase, b: StatusHostCfgBase) => a.label.localeCompare(b.label))}
      pagination={false}
    />
    {type === STATUS.IGNORED && <p>Health Status of individual devices can be set to "Ignored".<br />
      You can configure this property in the device configuration form.</p>}
  </>;
};

export default StateTable;
