import { BOX_TYPE } from '@const/pageResponse.constants';
import { FourGraphBox, Page, PageResponseDTO, RegroupButtonBox, RegroupGraph, Tab } from '@dto/pageResponse.dto';
import { Button } from 'antd';
import axios from 'axios';
import _ from 'lodash';
import { FC, Suspense, createContext, useEffect, useRef, useState } from 'react';
import { FaLayerGroup, FaUndo } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import { RegroupedGraphBox, RegroupedGraphIntervalLabel } from 'src/boxParts/graph/RegroupedGraphPart';
import { Spinner } from 'src/component/spinner/Spinner';
import TabsSticky from 'src/component/stickyTab/TabsSticky';
import BoxPage from 'src/content/basePage/BoxPage';
import { RoutePath } from 'src/data/Routes';
import { Log } from 'src/service/Log';
import { GLOB } from 'src/util/Glob';
import { TextUtil } from 'src/util/TextUtil';

import { getApi } from 'src/util/apiCalls';
import UniversalGroups from '../configuration/device/serverGroups/UniversalGroups';
import './BasePage.less';

export interface BasePageProps {
  url: string;
}

function getTabKey(tab: Tab) {
  return removeWhitespace(tab?.title);
}

function removeWhitespace(text: string) {
  return text?.replace(/\s+/g, '');
}

type DeepReadonly<T> = {
  readonly [P in keyof T]: DeepReadonly<T[P]>
}

const TABS_DURATION: Readonly<DeepReadonly<Tab<RegroupedGraphBox>>[]> = [
  { title: RegroupedGraphIntervalLabel.day, content: [{ graphs: [], interval: 'day', url: null, type: 'RegroupedGraphBox' }] },
  { title: RegroupedGraphIntervalLabel.week, content: [{ graphs: [], interval: 'week', url: null, type: 'RegroupedGraphBox' }] },
  { title: RegroupedGraphIntervalLabel.month, content: [{ graphs: [], interval: 'month', url: null, type: 'RegroupedGraphBox' }] },
  { title: RegroupedGraphIntervalLabel.year, content: [{ graphs: [], interval: 'year', url: null, type: 'RegroupedGraphBox' }] }
];

export const TabsContext = createContext([] as Tab[]);

export const BasePage: FC<BasePageProps> = (props) => {
  const [page, setPage] = useState<Page>();
  const [tabs, setTabs] = useState<Tab[]>([]);
  const [loading, setLoading] = useState(!props.url);
  const aborter = useRef<AbortController>();
  const location = useLocation();
  const navigate = useNavigate();
  const [hash, setHash] = useState<{ tab?: string }>({});

  useEffect(() => {
    let lochash = hash;
    if (location.hash) {
      lochash = parseHash();
      setHash(lochash);
    }
    if (!aborter.current?.signal.aborted) {
      reloadData(lochash);
    }
  }, [props.url]);

  useEffect(() => {
    return () => {
      aborter.current?.abort();
    }
  }, []);

  function parseHash() {
    return location.hash.substring(1).split('&').map(kv => kv.split('='))
      .reduce((prev, [key, value]) => ({ ...prev, [key]: removeWhitespace(decodeURIComponent(value)) }), {});
  }

  function reloadData(lochash = hash) {
    setLoading(true);
    aborter.current?.abort();
    aborter.current = new AbortController();
    getApi<PageResponseDTO>(props.url, aborter.current?.signal).then(
      (response) => {
        const p = response.data.data;
        if (!p?.tabs?.length) {
          console.log('No tab', p);
        } else if (lochash.tab) {
          const found = p.tabs.find(t => getTabKey(t) === lochash.tab);
          if (found) {
            setActiveTab(lochash.tab);
            // } else if (isDurationTab(lochash.tab, p.tabs)) {
            //   setTimeout(() => {
            //     regroupTabs(p.tabs, lochash);
            //   });
          } else {
            setActiveTab(null);//getTabKey(p.tabs[0]));
          }
        }
        setPage(p);
        setTabs(p.tabs);
        setTimeout(() => {
          setLoading(false);
        });
      },
      (reason) => {
        setLoading(false);
        if (axios.isCancel(reason)) return;
        Log.error('Failed to get tab page!', reason);
      }
    );
  }

  function isDurationTab(tab: string, loctabs = tabs) {
    return loctabs?.some(t => t.content?.some(c => c.type === 'FourGraphBox'))
      && TABS_DURATION.some(t => getTabKey(t as Tab<RegroupedGraphBox>) === tab);
  }

  function setActiveTab(tabKey: string) {
    if (tabKey)
      hash.tab = tabKey;
    else
      delete hash.tab;
    const hashes = [];
    for (const key in hash) {
      if (Object.hasOwn(hash, key)) {
        hashes.push(key + '=' + encodeURIComponent(hash[key]));
      }
    }
    const hashStr = hashes.length ? '#' + hashes.join('&') : '';
    navigate(decodeURIComponent(location.pathname + location.search) + hashStr, { replace: true });
    setHash({ ...hash });
  }

  function getActiveTab(loctabs = tabs, lochash = hash) {
    if (lochash.tab)
      return loctabs.find(t => getTabKey(t) === lochash.tab) ?? loctabs[0];
    return loctabs[0];
  }

  function regroupTabs(loctabs = tabs, lochash = hash) {

    const durationTabs = _.cloneDeep(TABS_DURATION) as Tab<RegroupedGraphBox>[];
    const daily = durationTabs[0].content[0];
    const weekly = durationTabs[1].content[0];
    const monthly = durationTabs[2].content[0];
    const yearly = durationTabs[3].content[0];
    daily.url = weekly.url = monthly.url = yearly.url = props.url;

    const selectedTab = getActiveTab(loctabs, lochash);
    const regroupBox = selectedTab.content.find(b => b.type === 'RegroupButtonBox') as RegroupButtonBox;
    if (regroupBox) {
      const regs: RegroupGraph[] = regroupBox.graphs;//.map(gb => ({ ...gb, type: 'FourGraphBox' }));
      daily.graphs.push(...regs);
      weekly.graphs.push(...regs);
      monthly.graphs.push(...regs);
      yearly.graphs.push(...regs);
    }
    else {
      for (const tab of loctabs) {
        if (tab.content[0].type === 'FourGraphBox') {
          const reg = { ...tab.content[0] as FourGraphBox, label: tab.title, tab: tab.title, url: props.url } as RegroupGraph;
          daily.graphs.push(reg);
          weekly.graphs.push(reg);
          monthly.graphs.push(reg);
          yearly.graphs.push(reg);
        } else {
          (durationTabs as Tab[]).push(tab);
        }
      }
    }

    if (!isDurationTab(lochash.tab, loctabs))
      setActiveTab(getTabKey(durationTabs[0]));
    setTabs(durationTabs);
  }

  function regroupTabsBack() {
    setActiveTab(getTabKey(page.tabs[0]));
    setTabs(page.tabs);
  }

  if (page && page.formPage) {
    return <UniversalGroups label={page.formPage.title} hwTypes={page.formPage.hwTypes} />
  }

  return <div className='xm-base-page'>{loading ? (
    <Spinner delay={100} />
  ) : !page?.tabs ? (
    <Spinner delay={1000} tip={'No tab! ' + TextUtil.formatJSON(page)} />
  ) : !page.tabs.length ? <div className='xm-base-single'>There is no data available for this page. {GLOB.selectedClass === RoutePath.CUSTOM_GROUPS ? 'Please check definition of your custom group.' : ''}</div>
    : page.tabs.length > 1 ? (
      <TabsContext.Provider value={tabs}>
        <TabsSticky activeKey={hash.tab} onChange={setActiveTab}
          className="xm-base-tabs" items={tabs.map(t => ({
            key: getTabKey(t),
            label: t.title,
            className: 'xm-base-tab',
            children: <>
              {tabs.filter(t => t.content?.some(c => c.type === 'FourGraphBox')).length > 1
                && <Button className='xm-regroup-btn' type='text' title="Regroup tabs by graph's time ranges" onClick={e => regroupTabs()}><FaLayerGroup /></Button>}
              {tabs.some(t => t.content?.some(c => c.type === 'RegroupedGraphBox'))
                && <Button className='xm-regroup-btn' type='text' title="Regroup tabs back by metric" onClick={regroupTabsBack}><FaUndo /></Button>}
              <Suspense fallback={<Spinner />}><BoxPage tab={t} url={props.url} /></Suspense>
            </>
          }))} />
      </TabsContext.Provider>
    ) : page.tabs[0].content.some(c => c.type === BOX_TYPE.TopologyBox) ? <Suspense fallback={<Spinner />}><BoxPage tab={page.tabs[0]} url={props.url} /></Suspense>
      : <div className='xm-base-single'><Suspense fallback={<Spinner />}><BoxPage tab={page.tabs[0]} url={props.url} /></Suspense></div>
  }</div>;
};