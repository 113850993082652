import { useEffect, useRef, useState } from "react";
import { Log } from "src/service/Log";
import { GLOB } from "src/util/Glob";
import useTimeout from "./useTimeout";

const useWebSocket = <T>(url: string, reconnect = 300000) => {
  const [initUrl] = useState(url);
  const [data, setData] = useState<T>();
  const ws = useRef<WebSocket>();
  const [isConnected, setIsConnected] = useState(false);
  const init = useRef(false);
  const reload = useTimeout(reconnect, !init.current ? false : !isConnected);

  useEffect(() => {
    if (init && ws.current && !isInPositiveState(ws.current))
      Log.error(`Failed to open WebSocket '${initUrl}'.`);

    if (!init)
      init.current = true;

    ws.current?.close(1000);
    ws.current = new WebSocket(`${GLOB.SERVER_WS_URL}${initUrl}`);
    ws.current.onopen = () => setIsConnected(true);
    ws.current.onclose = (event) => {
      // TODO: to use this, it is necessary to sync BE&FE usage of WS
      // if (!event.wasClean && event.reason)
      //   Log.error(`Close of WebSocket '${url}' was unexpected.`, event.reason);

      setIsConnected(false);
      // if (event.code > 1000 && GLOB.isProdEnv()) {
      //   Log.warn('Connection with server lost, you may be logged out.', 0);
      // }
    }
    ws.current.onmessage = (event) => setData(JSON.parse(event.data));

    return () => {
      if (ws.current.readyState == ws.current.OPEN)
        ws.current.close(1000, 'Navigated away from a page.');
    }
  }, [initUrl, reload]);

  return data;
}

const isInPositiveState = (ws: WebSocket) => {
  return ws.readyState == ws.OPEN || ws.CONNECTING;
};

export default useWebSocket;