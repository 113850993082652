import { Item } from '@dto/architecture.dto';
import { useState } from 'react';
import { ColProps, GridScope, IGridEntry } from 'src/component/grid/Grid';
import { LinkXM } from 'src/component/link/LinkXM';
import { GLOB } from 'src/util/Glob';

interface Props {
  items: Item[];
}

const SearchTab = ({ items }: Props) => {
  const [gs] = useState(() => new GridScope<Item>());

  return <gs.Grid
    className="xm-table-fit"
    columns={columns}
    dataSource={items}
    showSorterTooltip={false}
    pagination={false}
    rowKey={uniqKey}
  />;
}

const uniqKey = (item: Item) => item.item_id;
const columns: ColProps<Item & IGridEntry>[] = [
  {
    title: "Item",
    dataIndex: "label",
    key: "lab",
    minWidth: "5em",
    filter: { field: ['label'] },
    render: (text: string, item: Item) => <LinkXM to={GLOB.menuService.getRouteLink(item.url)}>{text}</LinkXM>,
    sorter: (a, b) => GLOB.naturalSort(a.label, b.label),
  },
  {
    title: "Root parent",
    dataIndex: "root_parent",
    key: "parent",
    minWidth: "9em",
    filter: { field: ['root_parent'] },
    render: (_, item: Item) => item.root_parent ? item.root_parent.label : "",
    sorter: (a, b) => GLOB.naturalSort(a.root_parent, b.root_parent),
  },
  {
    title: "Subsystem",
    dataIndex: "subsystem",
    minWidth: "9em",
    key: "subs",
    filter: { field: ['subsystem'] },
    sorter: (a, b) => GLOB.naturalSort(a.subsystem, b.subsystem),
  },
  {
    title: "Device class",
    minWidth: "9em",
    dataIndex: "hw_type",
    key: "hw",
    filter: { field: ['hw_type'] },
    sorter: (a, b) => GLOB.naturalSort(a.hw_type, b.hw_type),
  },
];

export default SearchTab;