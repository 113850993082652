import { Property } from 'csstype';
import { ReactElement } from "react";
import ReactDOMServer from "react-dom/server";
import { Log } from 'src/service/Log';

export abstract class TextUtil {

  /**
   * {@link JSON.stringify} with default spacing=2, and {@link TextUtil.hideKeysReplacer} applied
   * @param json A JavaScript value, usually an object or array, to be converted
   * @param replacer An array of strings and numbers that acts as an approved list for selecting the object properties that will be stringified
   * @param space Adds indentation, white space, and line break characters to the return-value JSON text to make it easier to read, default is set to 2
   * @returns formatted string
   */
  static formatJSON(json, replacer?: (string | number)[], space: string | number = 2) {
    let replacedObj = json;
    if (replacer) {
      const replacedStr = JSON.stringify(json, replacer);
      replacedObj = JSON.parse(replacedStr);
    }
    return JSON.stringify(replacedObj, TextUtil.hideKeysReplacer, space);
  }

  static readonly OBJ_REGEX = /^\s*\{.*\}\s*$/;
  static readonly KEYS_TO_HIDE = ['password', 'authorization'];
  static readonly VALUE_REMOVED = "-=removed=-";
  /**
   * Replacer for {@link JSON.stringify} hiding password like key values
   * @param key
   * @param value
   * @returns
   */
  static hideKeysReplacer(key: string, value: unknown) {
    const lowerKey = key.toLowerCase();
    if (TextUtil.KEYS_TO_HIDE.some(k => lowerKey.includes(k))) {
      return TextUtil.VALUE_REMOVED;
    }
    if (typeof value === 'string' && TextUtil.OBJ_REGEX.test(value)) {
      try {
        const valObj = JSON.parse(value);
        return JSON.stringify(valObj, TextUtil.hideKeysReplacer);
      } catch (e) {
        Log.debug('Failed to parse JSON like string', e);
        return value;
      }
    }
    return value;
  }

  /**
   * Returns text shorten to maxLength characters with '...' in middle
   * @param text
   * @param maxLength default 32 chars
   * @returns
   */
  static trimMiddle<T>(text: T, maxLength = 32) {
    if (typeof text !== 'string' || text?.length < maxLength || maxLength < 3) return text;
    const halfMaxLength = Math.floor((maxLength - 1) / 2);
    return text.substring(0, halfMaxLength) + '…' + text.substring(text.length - halfMaxLength);
  }

  /**
   * Returns text shorten to maxLength characters with '...' at end
   * @param text string to trim
   * @param maxLength default is to keep 32 characters
   * @returns
   */
  static trimEnd<T>(text: T, maxLength = 32) {
    if (typeof text !== 'string' || text?.length < maxLength || maxLength < 3) return text;
    return text.substring(0, maxLength) + '…';
  }

  /**
   * Capitalize first letter, keep rest as is
   * @param text
   */
  static capitalize(text: string) {
    if (!text) return text;
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  /**
   * Returns width of text
   * @param text text to measure
   * @param width initial CSS width of container, null=auto
   * @param refEl element whose CSS style to use
   * @returns
   */
  static measureText(text: React.ReactNode, width?: Property.Width, refEl?: HTMLElement) {
    if (!text) return;
    const style = getComputedStyle(refEl ?? document.body);
    const font = `${style.fontWeight} ${style.fontSize} ${style.fontFamily}`;
    const div = document.body.querySelector<HTMLElement>('div#textMeasure');
    if (typeof text === 'object') {
      // const root = createRoot(div);
      // flushSync(() => root.render(text));

      const errOrig = console.error;
      try {
        console.error = () => void 0;
        div.innerHTML = ReactDOMServer.renderToString(text as ReactElement);
      } finally {
        console.error = errOrig;
      }

    }
    else div.innerHTML = String(text);
    div.style.width = String(width || 'auto');
    div.style.font = font;
    return div.clientWidth;
  }

}