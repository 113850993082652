import { useEffect, useRef, useState } from "react";

const useTimeout = (timeout?: number, shouldRun = true): boolean => {
  const [reload, setReload] = useState(false);
  const timer = useRef<number>();

  useEffect(() => {
    if (timer.current)
      clearTimeout(timer.current)

    if (shouldRun)
      timer.current = window.setTimeout(() => setReload(!reload), timeout);

    return () => clearTimeout(timer.current);
  }, [reload, timeout, shouldRun]);

  return reload;
};

export default useTimeout;