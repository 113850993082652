import { FC, useEffect } from "react";
import { Spinner } from "src/component/spinner/Spinner";
import { GLOB } from "src/util/Glob";

export const Logout: FC = () => {

  useEffect(() => {
    GLOB.authService.logOut();
  }, []);

  return <Spinner />;
}