import { HEATMAP_FOLDER_ITEM_ID } from "@dto/constants/dashboard.constants";
import { Button, ButtonProps } from "antd";
import axios from "axios";
import { FC, useEffect } from "react";
import { FaStar } from "react-icons/fa";
import { API_URL } from "src/data/Api";
import { appGlobalSlice } from "src/redux/appGlobalStore";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { Log } from "src/service/Log";
import { putApi } from "src/util/apiCalls";
import { GLOB } from "src/util/Glob";

export function getCgFavoriteURL() {
  return `${API_URL.CUSTOM_GROUPS}/${GLOB.selectedItem.itemId}/favorite`;
}

export const CgHeatmapFavorite: FC<ButtonProps> = ({ className, ...props }) => {
  const dispatch = useAppDispatch();
  const isFav = useAppSelector(store => store.appGlobals.isCgHeatDash);

  useEffect(() => {
    return () => {
      setIsFav(false);
    }
  }, []);

  function setIsFav(fav: boolean) {
    dispatch(appGlobalSlice.actions.setCgHeatDash(fav));
  }

  function resetCgDashMenu() {
    const menu = GLOB.getMenu();
    menu[0].children.find(mn => mn.itemId === HEATMAP_FOLDER_ITEM_ID).children = null;
    GLOB.setMenu([...menu]);
  }

  const onSelect: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (isFav) {
      axios.delete(getCgFavoriteURL()).then(resp => {
        setIsFav(false);
        resetCgDashMenu();
      }, reason => Log.error('Failed to delete CG heatmap favorite!', reason));
    } else {
      putApi(getCgFavoriteURL()).then(resp => {
        setIsFav(true);
        resetCgDashMenu();
      }, reason => Log.error('Failed to add CG heatmap favorite!', reason));
    }
  }

  return <Button className={`xm-cg-heatmap-fav ${isFav ? "selected" : ""} ${className}`}
    title={isFav ? 'Remove heatmap from dashboard favorites' : 'Add heatmap to dashboard favorites'}
    onClick={onSelect} type='link' size="small" {...props}>
    <FaStar />
  </Button>;
}