import { Export } from "@dto/pageResponse.dto";
import { Dropdown, } from "antd";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { AxiosRequestConfig } from "axios";
import { FC, useState } from "react";
import { IconType } from "react-icons";
import { BsFiletypeCsv, BsFiletypeJson, BsFiletypePdf, BsFiletypeXlsx } from "react-icons/bs";
import { FaDownload } from "react-icons/fa";
import { ApiDownloader } from "src/component/apiDownloader/ApiDownloader";
import './ExportIcon.less';

export const EXPORT_TYPES = { PDF: BsFiletypePdf, CSV: BsFiletypeCsv, XLSX: BsFiletypeXlsx, JSON: BsFiletypeJson } as const; //['csv', 'xlsx', 'json', 'pdf'] as const;

//type ExportType = typeof EXPORT_TYPES[keyof typeof EXPORT_TYPES];
type ExportTypes = {
  [key in keyof typeof EXPORT_TYPES]?: boolean;
};

interface Props {
  export: Export;
  exportableTypes?: ExportTypes;
  size?: 'large' | 'normal';
}

const allOptions: ExportTypes = {};
Object.keys(EXPORT_TYPES).forEach(type => { allOptions[type] = true })

const ExportIcon: FC<Props> = ({ export: { method, url, body, fileName }, exportableTypes: exportAbleTypes = allOptions, size = 'normal' }) => {
  const [downloadCfg, setDownloadCfg] = useState<AxiosRequestConfig>();
  const [items] = useState<ItemType[]>(() => {
    const items = [];
    Object.keys(EXPORT_TYPES).forEach(type => {
      if (exportAbleTypes[type] || exportAbleTypes[type] === undefined)
        items.push({ key: type, label: <a>{type.toUpperCase()}</a> })
    });

    return items;
  });

  if (!items.length)
    return null;

  if (items.length == 1) {
    const key = items[0].key as string;
    const handleClick = () => setDownloadCfg({ method, url, data: body, params: { format: (key as string).toLowerCase() } });
    const Icon: IconType = EXPORT_TYPES[key];

    return <a className="export" title={"Export as " + key}>
      <Icon className={`xm-icon xm-icon-file xm-icon-file-${key} xm-icon-${size}`} onClick={handleClick} />
      <ApiDownloader fileName={`${fileName}.${items[0].key}`} request={downloadCfg} />
    </a>;
  }

  const handleClick = (info: ItemType) => setDownloadCfg({ method, url, data: body, params: { format: info.key.toString().toLowerCase() } });

  return (
    <a className="export">
      <Dropdown
        menu={{
          className: "xm-export-options",
          items,
          onClick: handleClick
        }}
        placement="bottomRight"
      >
        <FaDownload className={`xm-icon xm-icon-${size}`} />
      </Dropdown>
      <ApiDownloader fileName={`${fileName}.${downloadCfg?.params.format}`} request={downloadCfg} />
    </a>
  );
};

export default ExportIcon;