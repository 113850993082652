export const LEVEL = {
  info: 'info',
  warning: 'warning',
  error: 'error',
} as const;

export const RELOAD_PART = {
  healthStatus: 'health-status',
  menu: 'menu',
  user: 'user',
} as const;