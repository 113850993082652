import { Button, Form } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Parser } from "node-sql-parser/build/postgresql";
import { FC, useState } from "react";
import { Spinner } from "src/component/spinner/Spinner";
import { Log } from "src/service/Log";
import { getSQLLogs } from "../api/LogExporterAPI";



const LogExporterForm: FC = () => {
  const [formSQL] = Form.useForm<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [hasSyntaxError, setHasSyntaxError] = useState(false);

  const setSQLError = (error) => {
    formSQL.setFields([{
      name: "sql",
      errors: [`Provided SQL is invalid please check if you copied the SQL statement correctly. Syntax error at line ${error.location.start.line} and column ${error.location.start.column}`]
    }]);
    setHasSyntaxError(true);
    Log.error("SQL Syntax Error", error);
  }

  const processFileDownload = (data: Blob) => {
    const href = window.URL.createObjectURL(data);

    const anchorElement = document.createElement('a');

    anchorElement.href = href;
    anchorElement.download = "logs.tar.gz";

    document.body.appendChild(anchorElement);
    anchorElement.click();

    document.body.removeChild(anchorElement);
    window.URL.revokeObjectURL(href);
  }

  const submitSQLExport = async (values) => {

    setIsLoading(true);
    setHasSyntaxError(false);

    formSQL.setFields([{
      name: "sql",
      errors: [``]
    }]);

    try {
      if (values.sql) {
        const parser = new Parser();
        parser.astify(values.sql);
      }

      const data: Blob = await getSQLLogs({ sql: values.sql });
      processFileDownload(data);

    } catch (error) {
      console.error(error);
      if (error.name === "SyntaxError") {
        setSQLError(error);
      }
    }

    setIsLoading(false);

  }

  return (
    <div>
      <Form
        form={formSQL}
        onFinish={submitSQLExport}
        style={{
          position: "relative"
        }}
      >
        <Form.Item
          name="sql"
        >
          <TextArea
            rows={10}
            cols={50}
            placeholder="Enter SQL provided by support team. This can be left Empty"
            disabled={isLoading}
            style={hasSyntaxError ? { outline: "solid red 1px" } : {}}
          />
        </Form.Item>
        <Button
          disabled={isLoading}
          type="primary"
          htmlType="submit"
        >
          Export All Logs
        </Button>
        {
          isLoading && <Spinner />
        }
      </Form>
    </div>
  )
}

export default LogExporterForm