import { BackendDTO } from '@dto/backendResponse.dto';
import { MailGroup } from '@dto/mailGroup.dto';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MenuNode } from 'src/model/MenuNode';

export class AppGlobalState {
  topMenuSelectedKeys: string[] = [];
  topMenu: MenuNode[] = [];
  info: BackendDTO;
  emailGroups: MailGroup[] = [];
  isCgHeatDash = false;
}

export const appGlobalSlice = createSlice({
  name: 'appGlobals',
  initialState: new AppGlobalState(),
  reducers: {
    setCgHeatDash: (state, info: PayloadAction<boolean>) => {
      return { ...state, isCgHeatDash: info.payload };
    },
    setInfo: (state, info: PayloadAction<BackendDTO>) => {
      return { ...state, info: info.payload };
    },
    setTopMenu: (state, menu: PayloadAction<MenuNode[]>) => {
      return { ...state, topMenu: menu.payload };
    },
    setTopMenuSelectedKeys: (state, keys: PayloadAction<string[]>) => {
      return { ...state, topMenuSelectedKeys: keys.payload };
    },

    addEmailGroup: (state, group: PayloadAction<MailGroup>) => {
      state.emailGroups.push(group.payload);
    },
    removeEmailGroup: (state, id: PayloadAction<string | number>) => {
      return { ...state, emailGroups: state.emailGroups.filter(mg => mg.mail_group_id != id.payload) };
    },
    updateEmailGroup: (state, group: PayloadAction<MailGroup>) => {
      const idx = state.emailGroups.findIndex(mg => mg.mail_group_id === group.payload.mail_group_id);
      state.emailGroups[idx] = group.payload;
    },
    setEmailGroups: (state, replacement: PayloadAction<MailGroup[]>) => {
      return { ...state, emailGroups: replacement.payload };
    }
  },
});
