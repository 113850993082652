import { FC, useEffect, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { menuItemsRight } from "src/data/menu-items";
import { RoutePath } from "src/data/Routes";
import { MenuNode } from "src/model/MenuNode";
import { Log } from "src/service/Log";
import { GLOB } from "src/util/Glob";
import { EmptyPage } from "./EmptyPage";

export const RouteCmp: FC = () => {
  const match = useMatch(RoutePath.MATCH_CMP);
  const navigate = useNavigate();
  const [cmp, setCmp] = useState<JSX.Element>();

  useEffect(() => {
    GLOB.navAborter?.abort();
    const aborter = GLOB.navAborter = new AbortController();
    GLOB.xormonReady.promise.then(() => {
      const cmpPath = '/' + match.params.cmp as RoutePath;
      let mi = menuItemsRight.find((mn) => mn.path === cmpPath);
      const menu = GLOB.getActiveMenu();
      if (!mi) {
        mi = menu.find((mn) => mn.path === cmpPath);
      }
      if (!mi) {
        mi = menuItemsRight.filter(mn => !mn.path)?.flatMap(mn => mn.children).find(mn => mn.path === cmpPath);
      }
      if (!mi) {
        Log.warn('No menu found: ' + cmpPath);
        if (GLOB.getTree()?.children)
          return;
        if (aborter.signal.aborted) return;
        navigate(GLOB.getDefaultPath());

        return;
      }
      setTimeout(() => {
        if (aborter.signal.aborted) return;
        if ((!GLOB.selectedItem || MenuNode.getTopParent(GLOB.selectedItem).path !== cmpPath) && mi.children?.length) {
          const firstPath = MenuNode.getNodePath(MenuNode.selectFirst(mi.children));
          navigate(cmpPath + firstPath, { replace: true });
        }
        GLOB.selectedAllType = null;
        GLOB.selectedClass = cmpPath;
        GLOB.selectedType = null;
        GLOB.setTree(mi?.children ? mi : mi.path === RoutePath.PROFILE ? { key: RoutePath.PROFILE, title: 'User Profile', children: [{ ...mi, path: '' }] } : null);
      });
      setCmp(mi.component);
    }, reason => Log.error('Failed to navigate!', reason));

    return () => {
      aborter.abort();
    }
  }, [match.params]);

  return cmp || <EmptyPage />;
}