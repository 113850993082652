import { FC, useEffect, useState } from "react";
import { useLocation, useMatch } from "react-router-dom";
import { AllType, isAllType, RoutePath } from "src/data/Routes";
import NavigateRoute, { NavRouteProps } from "./NavigateRoute";

export const RouteSectionCmp: FC = () => {
  const { search } = useLocation();
  const match = useMatch(RoutePath.MATCH_SECTION_CMP);
  const [navProps, setNavProps] = useState<NavRouteProps>();

  useEffect(() => {
    updateProps();
  }, [match.params, search]);

  function isDevice(section: string) {
    const secPath = '/' + section;
    return secPath !== RoutePath.DASHBOARD && secPath != RoutePath.CUSTOM_GROUPS && secPath !== RoutePath.SETTINGS;
  }

  function updateProps() {
    const cls = match.params.section;
    let cmp = match.params.cmp;
    const cmpPath = '/' + cmp;
    let allType: AllType;
    let typeOrDevice: string;
    if (isAllType(cmpPath)) {
      allType = cmpPath;
      cmp = null;
    } else if (isDevice(cls)) {
      typeOrDevice = cmp;
      cmp = null;
    }
    setNavProps({ clsName: cls, allType, typeOrDevice, itemName: cmp, search });
  }

  return navProps && <NavigateRoute {...navProps} />;
}