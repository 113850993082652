import { FC } from "react"
import LogExporterDescription from "./components/LogExporterDescription"
import LogExporterForm from "./components/LogExporterForm"


const LogExporter: FC = () => {
  return (
    <div style={{ maxWidth: 700 }}>
      <LogExporterDescription/>
      <LogExporterForm/>
    </div>
  )
}

export default LogExporter