import { BasicResponseDTO } from "@dto/basicResponse.dto";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Log } from "src/service/Log";

interface Data<T> extends BasicResponseDTO {
  data: T;
}

type LOAD_DATA_STATE = 'NO' | 'YES' | 'SKIP_INIT';

const useGetApiData = <T, P extends Data<T>>(
  apiPath: string,
  errorMsg: string,
  deps: readonly unknown[],
  initialData?: T | (() => T),
  loadData: LOAD_DATA_STATE = 'YES',
  showError = true,
  apiParams?: any): [T, React.Dispatch<React.SetStateAction<T>>, boolean, AbortController] => {
  const [apiInit] = useState(apiPath);   // TODO: In CG parents comp, the api prop is changing. Prevent or modify this behaver.
  const [data, setData] = useState<T>(initialData);
  const [loading, setLoading] = useState(loadData !== 'SKIP_INIT');
  const controller = useRef<AbortController>();
  const initRun = useRef(true);

  useEffect(() => {
    if (initRun.current && loadData === 'SKIP_INIT')
      return () => {
        initRun.current = false;
      };

    if (loadData === 'NO') {
      setLoading(false);
      return;
    }

    setLoading(true);
    controller.current = new AbortController();
    const getData = () => {
      axios.get<P>(apiPath, { signal: controller.current.signal, params: apiParams }).then(
        ({ data: { data } }) => setData(data),
        reason => {
          if (showError)
            Log.error(errorMsg, reason)
        }
      ).finally(() => setLoading(false));
    }

    getData();
    return () => {
      controller.current.abort();
      initRun.current = false;
    }
  }, [...deps, loadData, apiInit]);

  return [data, setData, loading, controller.current];
}

export default useGetApiData;