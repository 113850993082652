import { BellOutlined } from "@ant-design/icons";
import { COLORS } from '@const/healthStatus.constants';
import { RELOAD_PART } from '@const/websocketMessage.constants';
import { useWebSocketReload } from "src/hook/useWebSocketReload";
import CounterCircle from "./CounterCircle";
import useHealthCount from './hook/useHealthCount';

const BellIcon = () => {
  const data = useWebSocketReload(RELOAD_PART.healthStatus);
  const state = useHealthCount([data]);

  if (state.bell <= 0)
    return <BellOutlined />

  return (
    <CounterCircle counter={state.bell} xShift={55} yShift={5} color={COLORS.error}>
      <BellOutlined />
    </CounterCircle>
  );
};

export default BellIcon;