export enum DEFERRED_STATUS {
  'resolved', 'rejected'
}

/**
 * Resettable promise with exported resolve/reject
 */
export class Deferred<T> {

  private accessed = false;
  private _status: DEFERRED_STATUS;

  promise: Promise<T>;
  resolve: (value?: T | PromiseLike<T>) => T | PromiseLike<T>;
  reject: (reason?: unknown) => void;

  /**
   * Returns 'resolved', 'rejected' or null (when not settled yet)
   */
  get status() {
    return this._status;
  }

  /**
   * Constructs resettable promise
   */
  constructor() {
    this.reset();
  }

  /**
   * Use only sequentially (must wait for promise after reset), resets only if already resolved/rejected
   */
  reset() {
    if (this.promise == null || this.accessed) {
      if (this.promise != null) this.reject('reset');
      this.accessed = false;
      this._status = null;
      this.promise = new Promise<T>((resolve, reject) => {
        this.resolve = (value?: T | PromiseLike<T>) => {
          this.accessed = true;
          this._status = DEFERRED_STATUS.resolved;
          resolve(value);
          return value;
        }
        this.reject = (reason?: unknown) => {
          this.accessed = true;
          this._status = DEFERRED_STATUS.rejected;
          reject(reason);
        }
      });
      // promise throws error if resolved without then
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this.promise.then(() => { }, () => { });
    }
  }
}