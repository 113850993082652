import { InfoCircleOutlined } from "@ant-design/icons";
import { notification } from "antd";

interface Props {
  title: string;
  info: string;
  reason: string;
}

const StateInfo = ({ title, info, reason }: Props) => {
  if (!info && !reason)
    return <></>;

  const decs = <>
    {info ? <><p>Health detail: <br />{info}</p></> : null}
    {reason ? <><p>Ignored reason: <br />{reason}</p></> : null}
  </>;

  const openNotification = () => {
    notification.open({
      message: title,
      description: decs
    });
  };

  return (
    <InfoCircleOutlined onClick={openNotification} />
  );
}

export default StateInfo;