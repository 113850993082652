import { Badge, BadgeProps } from "antd"
import { PresetStatusColorType } from "antd/lib/_util/colors"
import dayjs from "dayjs"
import { FC, useMemo } from "react"
import { useAppSelector } from "src/redux/hooks"
import { GLOB } from "src/util/Glob"

export const LicenseBadge: FC<BadgeProps> = props => {

  const info = useAppSelector((store) => store.appGlobals.info);
  const techno = info?.backend?.premium?.technologies;
  const status = useMemo<PresetStatusColorType>(() => {
    if (!techno?.length) return 'default';

    const dates = techno?.map(lt => dayjs(lt.expirationDate));
    const oldest = dates.reduce((prev, cur) => cur.isBefore(prev) ? cur : prev);
    const now = dayjs();

    if (oldest.isBefore(now)) return 'error';
    if (oldest.diff(now, 'M') < 2) return 'warning';
    return 'default';
  }, [info]);

  return GLOB.userInfo.isAdmin ? <Badge status={status} dot={status !== 'default'}
    title={status === 'error' ? "Your license has expired!" : "Your license will expire soon!"} {...props} />
    : <>{props.children}</>;
}