import { UniversalGroupHwInfo } from "@dto/universalGroupHwInfo.dto";
import { Form } from "antd";
import Modal from "antd/lib/modal/Modal";
import { useContext, useEffect } from "react";
import Rule, { defaultRule } from "src/component/selectItems/Rule";
import { RuleGroup } from "src/component/selectItems/RulesList";
import { SgroupFormData } from "./ServerGroupForm";
import { ServerGroupFormContext } from "./ServerGroupFormContext";

export interface SGroupItemsFormData {
    class: string,
    hw_type: string;
    subsystem: string;
    rule: RuleGroup;
}

interface AddModalProps {
    hwType: UniversalGroupHwInfo
}

export const AddServerModal = ({ hwType }: AddModalProps) => {

    const {
        isModalOpen,
        setIsModalOpen,
        setRuleGroup
    } = useContext(ServerGroupFormContext);

    const [form] = Form.useForm<SgroupFormData>();
    const ruleValues = Form.useWatch<RuleGroup>("rule", form);

    const defaultValues: SGroupItemsFormData = {
        class: hwType.class,
        hw_type: hwType.hwType,
        subsystem: hwType.subsystem,
        rule: defaultRule
    };

    const addServers = () => {
        setRuleGroup(ruleValues);
        setIsModalOpen(false);
    }

    useEffect(() => {
        form.setFieldsValue({
            ...defaultValues
        })
    }, []);

    return (
        <Modal
            title="Add Items"
            okText="Confirm"
            closable={false}
            open={isModalOpen}
            onCancel={() => {
                setIsModalOpen(false)
            }}
            onOk={() => { addServers(); }}
        >
            <Form
                form={form}
            >
                <Rule
                    cls={hwType.class}
                    noTagMode
                    linuxExclude={hwType.hwType === "linux"}
                />
            </Form>
        </Modal>
    )
}