import { Drawer } from "antd";
import { GLOB } from 'src/util/Glob';
import './GlobalHealthStatus.less';
import StateTabs from "./StateTabs";

const HealthStatusDrawer = () => {
  const handleClose = () => GLOB.setState({ isHSOpen: false });

  return (
    <Drawer
      title='Global Health Status'
      open={GLOB.getState().isHSOpen}
      onClose={handleClose}
      className='wrapper-glob-health-status'
    >
      <StateTabs />
    </Drawer>
  );
};

export default HealthStatusDrawer;