import { GRAPH_INTERVAL } from "@dto/constants/pageResponse.constants";
import { FindDashboardGraphDTO, FindGraph, FindGraphsDTO } from "@dto/dashboard.dto";
import { Box, GraphInterval, Metric } from "@dto/pageResponse.dto";
import { Component } from "react";
import { CommonGraphProps } from "src/component/graph/GraphTypes";
import { API_URL } from "src/data/Api";
import { postApi } from "src/util/apiCalls";
import { DateUtil } from "src/util/DateUtil";
import { GraphPlotUtil, GraphRangeState } from "../../component/graph/GraphPlotUtil";
import { Log } from "../../service/Log";
import { GLOB } from "../../util/Glob";
import { BoxPart } from "../BoxPart";

export class GraphPartProps<T extends Box> extends BoxPart<T>{
  url: string;
}

export interface AbstractGraphPartState {
  dashboardGraphs: FindGraph[];
}

export class ColorMap {
  colorPalette: Record<string, string> = {};
  colorIndex = 0;
}

export abstract class AbstractGraphPart<P extends Box, S extends AbstractGraphPartState = AbstractGraphPartState>
  extends Component<GraphPartProps<P>, S & GraphRangeState> {

  private static readonly OTHERS_UID = 'null';

  protected common: CommonGraphProps;
  protected interval = true;

  private timeoutShort = 0;
  private timeoutLong = 0;

  constructor(props: GraphPartProps<P>) {
    super(props);
    this.state = { ...this.state, ...DateUtil.initShort(), ...DateUtil.initLong() };
  }

  componentDidMount(): void {
    if (this.interval) {
      this.updateShort();
      this.updateLong();
      this.props.url && postApi<FindGraphsDTO, FindDashboardGraphDTO>(API_URL.DASHBOARD_FIND_GRAPH,
        { tab: this.props.tabName, url: this.props.url }).then(response => {
          const found = response.data.data;
          this.common.dashboardGraphs = found;
          this.setState({ dashboardGraphs: found });
        }, reason => Log.error('Failed to get graph dashboards!', reason));
    }
  }

  componentWillUnmount(): void {
    window.clearTimeout(this.timeoutShort);
    window.clearTimeout(this.timeoutLong);
  }

  protected getStart(interval: GraphInterval) {
    switch (interval) {
      case GRAPH_INTERVAL.day:
        return this.state.startDay;
      case GRAPH_INTERVAL.week:
        return this.state.startWeek;
      case GRAPH_INTERVAL.month:
        return this.state.startMonth;
      case GRAPH_INTERVAL.year:
        return this.state.startYear;
      default:
        Log.warn('Unknown graph interval: ' + interval);
    }
  }

  protected getEnd(interval: GraphInterval) {
    if (interval === GRAPH_INTERVAL.day)
      return this.state.endShort;
    else
      return this.state.endLong;
  }

  protected updateShort() {
    window.clearTimeout(this.timeoutShort);
    this.timeoutShort = window.setTimeout(() => {
      this.setState(prev => ({ ...prev, ...DateUtil.initShort() }));
      this.updateShort();
    }, GLOB.TIMEOUT_SHORT);
  }

  protected updateLong() {
    window.clearTimeout(this.timeoutLong);
    this.timeoutLong = window.setTimeout(() => {
      this.setState({ ...this.state, ...DateUtil.initLong() });
      this.updateLong();
    }, GLOB.TIMEOUT_LONG);
  }

  static mapColors(uuids: string[], metrics: Metric[], aggregated: boolean, colorMap: ColorMap = new ColorMap()) {
    if (!uuids || !metrics) return colorMap;
    if (GraphPlotUtil.isMetricItems(metrics, aggregated)) {
      for (const metric of metrics) {
        colorMap.colorPalette[metric.metric] = GLOB.colorPalette[colorMap.colorIndex];
        colorMap.colorIndex = (colorMap.colorIndex + 1) % GLOB.colorPalette.length;
      }
    }
    else {
      const otherUuids = [...uuids, AbstractGraphPart.OTHERS_UID];
      for (const uuid of otherUuids) {
        for (const metric of metrics) {
          colorMap.colorPalette[uuid + metric.metric] = GLOB.colorPalette[colorMap.colorIndex];
          colorMap.colorIndex = (colorMap.colorIndex + 1) % GLOB.colorPalette.length;
        }
      }
    }
    return colorMap;
  }
}