import { Tag, TreeNodeProps } from "antd";
import { useState } from "react";
import { GLOB } from "src/util/Glob";

interface Props {
  items: TagItem[];
  onClose: (id: string) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

export type TreeItem = Pick<TreeNodeProps, "title" | "key">;
export type TagItem = TreeItem & { disabled?: boolean };

function TagsList({ items, onClose }: Props) {
  const [tagItems, setTagItems] = useState<TagItem[]>([]);
  const [prevItems, setPrevItems] = useState<TagItem[]>([]);

  if (items !== prevItems) {
    setPrevItems(items);
    setTagItems(prev => {
      const tags: TagItem[] = items?.length ? [...items] : [];

      tags.forEach(item => {
        for (const tag of prev) {
          if (item.key === tag.key) {
            if (!item.title)
              item.title = tag.title;
            break;
          }
        }
      });

      return tags.sort((a, b) => GLOB.naturalSort(a.title, b.title));
    });
  }

  const handleClose = (key: string) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setTagItems(prev => prev.filter(item => key !== item.key));
    onClose(key)(e);
  };

  return (
    <div className="xm-tags-container">
      {tagItems.map(({ title, key, disabled }) => <Tag closable className={disabled ? 'xm-item-tag disabled' : 'xm-item-tag'} key={key} onClose={handleClose(key as string)}>{title}</Tag>)}
    </div>
  );
}


export default TagsList;