import { FC, useEffect, useState } from "react";
import { useLocation, useMatch } from "react-router-dom";
import { AllType, isAllType, RoutePath } from "src/data/Routes";
import NavigateRoute, { NavRouteProps } from "./NavigateRoute";

export const RouteClassTypeItem: FC = () => {
  const { search } = useLocation();
  const match = useMatch(RoutePath.MATCH_CLASS_TYPE_ITEM);
  const [navProps, setNavProps] = useState<NavRouteProps>();

  useEffect(() => {
    updateProps();
  }, [match.params, search]);

  function updateProps() {
    const cls = match.params.class;
    let type = match.params.type;
    const typePath = '/' + type;
    let allType: AllType;
    if (isAllType(typePath)) {
      allType = typePath;
      type = null;
    }
    const item = match.params.item;
    setNavProps({ clsName: cls, allType, typeOrDevice: type, itemName: item, search });
  }

  return navProps && <NavigateRoute {...navProps} />;
}