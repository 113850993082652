import axios, { AxiosResponse } from "axios"
import { LOG_EXPORTER_API } from "../interface/constatnts";
import { LogRequest } from "@dto/log.dto";

export async function getSQLLogs(sql: LogRequest) {
    try {
        const response = await axios.post<Blob, AxiosResponse<Blob, LogRequest>, LogRequest>(LOG_EXPORTER_API.EXPORT_ALL, sql, {responseType: "blob"});
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}