import { Button, ButtonProps } from "antd";
import { FC, useState } from "react";

interface AsyncButtonProps extends ButtonProps {
  /**
   * onClick is ignored in AsyncButton, sets loading until promise settled
   */
  onClickAsync: (e: React.MouseEvent<HTMLElement>) => Promise<unknown>;
}

export const AsyncButton: FC<AsyncButtonProps> =
  ({ onClickAsync, onClick, loading, ...props }) => {

    const [waiting, setWaiting] = useState(false);

    return <Button {...props} loading={loading || waiting} onClick={async e => {
      e.preventDefault();
      e.stopPropagation();
      if (waiting) return;
      if (onClickAsync) {
        setWaiting(true);
        await onClickAsync(e);
        setWaiting(false);
      }
      else if (onClick) {
        onClick(e);
      }
    }} />
  }