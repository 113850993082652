import { Dispatch, SetStateAction, createContext } from "react";
import { RuleGroup } from "src/component/selectItems/RulesList";
import { ItemNode } from "./ServerGroupForm";

interface iServerGroupFormContext {
    isModalOpen: boolean;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
    checkableServers: ItemNode[];
    setCheckableServers: Dispatch<SetStateAction<ItemNode[]>>;
    setRuleGroup: Dispatch<SetStateAction<RuleGroup>>;
    selectedServers: ItemNode[];
    setSelectedServers: Dispatch<SetStateAction<ItemNode[]>>;
}

export const ServerGroupFormContext = createContext<iServerGroupFormContext>(null)
