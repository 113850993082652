import { Checkbox, CheckboxProps } from "antd";

function TreeTitle({ label, ...rest }: CheckboxProps & { label: string | JSX.Element }) {
  return (
    <Checkbox {...rest} className="xm-tree-title" >
      {label}
    </Checkbox>
  );
}

export default TreeTitle;