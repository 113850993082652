import { FC, useEffect, useState } from "react";
import { useLocation, useMatch } from "react-router-dom";
import { RoutePath } from "src/data/Routes";
import { GLOB } from "src/util/Glob";
import NavigateRoute, { NavRouteProps } from "./NavigateRoute";

export const RouteDevice: FC = () => {
  const { search } = useLocation();
  const match = useMatch(RoutePath.MATCH_DEVICE);
  const [navProps, setNavProps] = useState<NavRouteProps>();

  useEffect(() => {
    updateProps();
  }, [match.params, search]);

  function updateProps() {
    const cls = match.params.class;
    let type = match.params.type;
    let item = match.params.item;
    const device = GLOB.menuService.storagesBy.device;
    if (!item && device.children && !device.children.some(mn => mn.title === type && mn.hwType)) {
      item = type;
      type = null;
    }
    return setNavProps({ clsName: cls, allType: RoutePath.DEVICE, typeOrDevice: type, itemName: item, search });
  }

  return navProps && <NavigateRoute {...navProps} />;
}