import { Count, CountDTO, } from '@dto/healthStatus.dto';
import useGetApiData from "src/hook/useGetApiData";

const useHealthCount = (deps: readonly unknown[] = []): Count => {
  const [state] = useGetApiData<Count, CountDTO>('/api/global_status/v1/count',
    `Failed to get Global Status count state`,
    [...deps],
    {
      bell: -1,
      error: -1,
      ignored: -1,
      ok: -1,
      unknown: -1,
      warning: -1,
      data: -1,
      connection: -1,
    }
  );

  return state;
};

export default useHealthCount;