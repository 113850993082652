import { Item } from "@dto/architecture.dto";
import { Form, Modal, ModalProps } from "antd";
import { FormProviderProps } from 'antd/lib/form/context';

interface Props<T> {
  initialValues: {
    items: T;
    subsystem?: string;
  };
  content: React.ReactNode
};

export interface FormParentSelectData {
  subsystem: string;
  items: Item[];
}

function useSelectModal<T>(providerProps: FormProviderProps, modalProps?: ModalProps): [(props: Props<T>) => void, React.ReactElement] {
  const [form] = Form.useForm();
  const [modal, contextHolder] = Modal.useModal();

  function showModal({ initialValues, content }: Props<T>) {
    form.setFieldsValue(initialValues);
    modal.confirm({
      ...modalProps,
      className: 'xm-select-modal',
      maskClosable: false,
      title: <div>Select Items</div>,
      icon: false,
      width: 'auto',
      centered: true,
      onOk: () => form.submit(),
      onCancel: () => form.setFieldsValue(initialValues),
      content: (
        <Form.Provider {...providerProps}>
          <Form form={form} name='selected-items' preserve={false}>
            {content}
          </Form>
        </Form.Provider>
      )
    });
  }

  return [showModal, contextHolder];
}

export default useSelectModal;