import { CreateMailGroupDTO, MailGroup, MailGroupResponseDTO, MailGroupsResponseDTO } from "@dto/mailGroup.dto";
import axios from "axios";
import { API_URL } from "src/data/Api";
import { Log } from "src/service/Log";
import { postApi, putApi } from "src/util/apiCalls";

export async function getEmails() {
  const promise = axios.get<MailGroupsResponseDTO>(API_URL.EMAIL_GROUPS);
  promise.catch(error => Log.error('Failed to get email groups!', error));
  return promise;
}

export async function deleteMailGroup(id: number) {
  const promise = axios.delete(API_URL.EMAIL_GROUPS + '/' + id);
  promise.catch(reason => Log.error('Failed to delete email group', reason));
  return promise;
}

export async function createMailGroup(data: CreateMailGroupDTO) {
  const promise = postApi<MailGroupResponseDTO, CreateMailGroupDTO>(API_URL.EMAIL_GROUPS, data);
  promise.catch(reason => Log.error('Failed to create mail group ' + data.label, reason));
  return promise;
}

export async function updateMailGroup(group: MailGroup) {
  const promise = putApi(API_URL.EMAIL_GROUPS + '/' + group.mail_group_id, group);
  promise.catch(reason => Log.error('Failed to update email group ' + group.label, reason));
  return promise;
}