import { ComponentClass, FC } from "react";
import { Location, NavigateFunction, useLocation, useNavigate } from "react-router-dom";

export interface WithRouter {
  location?: Location;
  navigate?: NavigateFunction;
}

export const withRouter = <C extends WithRouter>(Cmp: ComponentClass<C>) => {
  const Wrapper: FC<C> = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    return (
      <Cmp
        navigate={navigate}
        location={location}
        {...props}
      />
    );
  };

  return Wrapper;
};