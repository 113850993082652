import { FC } from "react";
import { useRouteError } from "react-router-dom";
import { Log } from "src/service/Log";
import { TextUtil } from "src/util/TextUtil";
import './ErrorPage.less';

let prevErrDetail;

export const ErrorPage: FC<{ detail }> = ({ detail, ...props }) => {

  if (detail && prevErrDetail != detail) {
    prevErrDetail = detail;
    Log.sendToBE({ level: "error", log: detail });
  }

  return (
    <div id="error-page">
      <h2>Navigation failed!</h2>
      <p>Sorry, an unexpected error has occurred.</p>
      <pre>{TextUtil.formatJSON(detail)}</pre>
    </div>
  );
}

export const ErrorRoutePage: FC = () => {
  const error = useRouteError() as Error;
  console.error(error);

  return <ErrorPage detail={error} />;
}