import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as _ from 'lodash-es';
import { Key } from 'react';
import { AlertTableData } from '../AlertingTypes';
import { AlertGroupTableData } from './AlertingTable';

export class AlertTab {
  key: string;
  label: string;
  class: string;
  alertGroups: AlertGroupTableData[];
  expandRowKey: Key;
}

const alertTabs: AlertTab[] = [];

function removeGroup(state: AlertTab[], data: PayloadAction<{ tabIndex: number; groupIndex: number; }>) {
  const tab = state[data.payload.tabIndex];
  const groups = tab.alertGroups;
  groups.splice(data.payload.groupIndex, 1);
}

export const alertSlice = createSlice({
  name: 'alerts',
  initialState: alertTabs,
  reducers: {
    addTab: (state, tab: PayloadAction<AlertTab>) => {
      state.push(tab.payload);
    },
    removeTab: (state, id: PayloadAction<string>) => {
      for (let index = 0; index < state.length; index++) {
        if (state[index].key === id.payload) {
          state.splice(index, 1);
          break;
        }
      }
    },
    setRowKeyToExpand: (state, data: PayloadAction<{ tabIndex: number, key: Key }>) => {
      state[data.payload.tabIndex].expandRowKey = data.payload.key;
    },
    addGroup: (state, data: PayloadAction<{ tabIndex: number, group: AlertGroupTableData }>) => {
      const tab = state[data.payload.tabIndex];
      tab.alertGroups.push(data.payload.group);
    },
    removeGroup,
    mergeGroup: (state, { payload }: PayloadAction<{ tabIndex: number, group: AlertGroupTableData }>) => {
      const p = payload;
      const tab = state[p.tabIndex];
      const groupIndex = tab.alertGroups.findIndex(g => g.key === p.group.key);
      const grp = { metrics: [], items: [], alerts: [] } as AlertGroupTableData;
      _.merge(grp, tab.alertGroups[groupIndex], p.group);
      //grp.items = [...grp.items];
      tab.alertGroups[groupIndex] = p.group;
    },
    // mergeGroups: (state, { payload: { tabIndex, groups } }: PayloadAction<{ tabIndex: number, groups: AlertGroupTableData[] }>) => {
    //   const tab = state[tabIndex];
    //   _.merge(tab.alertGroups, groups);
    //   // * _.merge from lodash is not right choice for changing values in multiselect ant.design comp
    //   tab.alertGroups.forEach((group, gIndex) => group.alerts.forEach((alert, aIndex) => { alert.targets = groups[gIndex].alerts[aIndex].emailGroups }));
    //   tab.alertGroups = [...tab.alertGroups];
    // },
    addAlert: (state, { payload: { alert, group, tabIndex } }: PayloadAction<{ tabIndex: number, group: AlertGroupTableData, alert: AlertTableData }>) => {
      const tab = state[tabIndex];
      const groupIndex = tab.alertGroups.findIndex(g => g.key === group.key);
      tab.alertGroups[groupIndex] = { ...group, alerts: [...group.alerts, alert] };
    },
    removeAlert: (state, data: PayloadAction<{ tabIndex: number, group: AlertGroupTableData, alertIndex: number }>) => {
      const tab = state[data.payload.tabIndex];
      const groupIndex = tab.alertGroups.findIndex(g => g.key === data.payload.group.key);
      const group = state[data.payload.tabIndex].alertGroups[groupIndex];
      if (group.alerts.length < 2) {
        removeGroup(state, { payload: { tabIndex: data.payload.tabIndex, groupIndex }, type: null });
      } else {
        group.alerts.splice(data.payload.alertIndex, 1);
      }
    },
    replaceGroups: (state, data: PayloadAction<{ tabIndex: number, groups: AlertGroupTableData[] }>) => {
      const tab = state[data.payload.tabIndex];
      tab.alertGroups = [...data.payload.groups];
    },
    replaceStore: (state, replacement: PayloadAction<AlertTab[]>) => {
      return replacement.payload;
    },
    setGroup: (state, data: PayloadAction<{ tabIndex: number, group: AlertGroupTableData }>) => {
      const p = data.payload;
      const tab = state[p.tabIndex];
      tab.alertGroups = tab.alertGroups.map(ag => ag.key === p.group.key ? p.group : ag);
    },
  },
});