import { ReloadPart, WebsocketReload } from "@dto/websocketMessage.dto";
import { useEffect, useState } from "react";
import useWebSocket from 'src/hook/useWebSocket';

export const useWebSocketReload = (reloadType: ReloadPart, reconnect?: number) => {
    const [data, setData] = useState<WebsocketReload>();
    const reload = useWebSocket<WebsocketReload>('/websocket/reload', reconnect);

    useEffect(() => {
        if (reload && reload.reload == reloadType)
            setData(reload);
    }, [reload, reloadType]);

    return data;
}

export const useWebSocketReloadRandom = (reloadType: ReloadPart, reconnect?: number) => {
    const [data, setData] = useState<number>();
    const reload = useWebSocket<WebsocketReload>('/websocket/reload', reconnect);

    useEffect(() => {
        if (reload && reload.reload == reloadType)
            setData(Math.random() * 1000);
    }, [reload, reloadType]);

    return data;
}
