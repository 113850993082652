import { EyeOutlined } from '@ant-design/icons';
import { Item, ItemGroupPreview } from '@dto/architecture.dto';
import { ClassKey } from '@dto/constants/class.constants';
import { ParentRegex } from '@dto/customGroup.dto';
import { Button, ButtonProps, Checkbox, Form, Radio, RadioChangeEvent, RadioGroupProps } from "antd";
import { FormItemProps } from 'antd/es/form';
import { CheckboxChangeEvent, CheckboxOptionType } from "antd/lib/checkbox";
import { FormProviderProps } from 'antd/lib/form/context';
import { NamePath } from 'antd/lib/form/interface';
import { createContext, useCallback, useContext, useMemo } from "react";
import { isClassLPAR } from 'src/data/Class';
import { Log } from 'src/service/Log';
import { Spinner } from '../spinner/Spinner';
import './Rule.less';
import { EnableItemId, FormRulesInstance, RuleGroup } from './RulesList';
import ModalSelectItems from './children/ModalSelectItems';
import RegexList from './children/RegexList';
import TagListItems from './children/TagList';
import TagsBox from './children/TagsBox';
import AllItems, { AllItemsContext, HwProps } from './comp/AllItems';
import AllTags from './comp/AllTags';
import TagsList from './comp/TagsList';
import { makePreviewApiData } from './comp/preview/CgPreviewHandler';
import { regexApi, usePreviewModal } from "./comp/preview/Preview";
import useParentTypes, { Option } from './hook/useParentTypes';
import useSelectModal from './hook/useSelectModal';
import ModalSelectParentItems, { FormSelectData as FormParentSelectData } from './parent/ModalSelectItems';
import RegexListParent from './parent/RegexList';
import TagListParent from './parent/TagList';

export const RuleContext = createContext<RulePropsBase & FormOperations>(undefined);
type ChangeCallback = (rule: RuleGroup) => RuleGroup;
interface FormOperations {
  setField: <V>(path: NamePath, value: V, validate?: boolean) => void;
  makeChange: (callback: ChangeCallback) => void;
  filterByParents: () => void
}

type RulePropsBase = RuleGroup & Pick<ItemGroupPreview, 'linuxExclude'> & { ruleIndex: number | string; };
export type ApiPrams = Record<string, unknown>;
export type AdditionalApiParams = Partial<{
  main: ApiPrams;
  parents: Partial<Record<'type' | 'select', ApiPrams>>;
  items: Partial<Record<'select', ApiPrams>>;
  preview: Partial<{
    parents: Partial<Record<'all' | 'item', ApiPrams>>;
    items: Partial<Record<'all' | 'item', ApiPrams>>;
  }>
}>;

interface RuleProps extends Pick<ItemGroupPreview, 'linuxExclude'> {
  cls: string;
  additionalApiParams?: AdditionalApiParams;
  noTagMode?: boolean;
  formItemProps?: FormItemProps
};

interface FormRuleInstance extends Omit<FormRulesInstance, 'rules'> {
  rule: RuleGroup;
}

export const AdditionalApiParamsContext = createContext<AdditionalApiParams>(undefined);
export default function Rule({ formItemProps, linuxExclude = false, additionalApiParams, ...rest }: RuleProps) {
  const form = Form.useFormInstance<FormRuleInstance>();
  const rule = Form.useWatch('rule', form); // doesn't like form.reset
  const { hw_type, subsystem }: FormRulesInstance = form.getFieldsValue(['hw_type', 'subsystem']);

  if (!rest.noTagMode) {
    return (
      <AdditionalApiParamsContext.Provider value={{ ...additionalApiParams }}>
        <AllTags>
          <AllItems cls={rest.cls} subsystem={subsystem} hw_type={hw_type} noTagMode={rest.noTagMode} linuxExclude={linuxExclude}>
            <Form.Item name='rule' {...formItemProps}>
              <RuleWrapper<FormRuleInstance> {...rule} ruleIndex={'rule'} linuxExclude={linuxExclude} />
            </Form.Item>
          </AllItems>
        </AllTags>
      </AdditionalApiParamsContext.Provider>
    );
  }

  return (
    <AdditionalApiParamsContext.Provider value={{ ...additionalApiParams }}>
      <AllItems cls={rest.cls} subsystem={subsystem} hw_type={hw_type} noTagMode={rest.noTagMode} linuxExclude={linuxExclude}>
        <Form.Item name='rule' {...formItemProps}>
          <RuleWrapper<FormRuleInstance> {...rule} ruleIndex={'rule'} linuxExclude={linuxExclude} />
        </Form.Item>
      </AllItems>
    </AdditionalApiParamsContext.Provider>

  );
}

export function RuleWrapper<T extends FormRulesInstance | FormRuleInstance>(props: RulePropsBase) {
  const { cls, subsystem, hw_type } = useContext(AllItemsContext);
  const form = Form.useFormInstance<T>();

  const setField = useCallback(<V,>(path: NamePath, value: V, validate = true) => {
    form.setFields([{
      touched: true,
      name: path,
      value
    }]);

    if (validate)
      void form.validateFields([path]);
  }, []);

  const makeChange = useMemo(() => {
    if (typeof props.ruleIndex === 'string')
      return (callback: ChangeCallback) => {
        setField<RuleGroup>('rule', callback(form.getFieldValue('rule') as RuleGroup), false);
      }

    return (callback: ChangeCallback) => {
      const rules = (form.getFieldValue('rules') as RuleGroup[]).map((rule, indx) => {
        if (indx !== props.ruleIndex)
          return rule;

        return callback(rule);
      });

      setField<RuleGroup[]>('rules', rules, false);
    }
  }, [props.ruleIndex]);

  const filterByParents = useCallback(() => {
    function getPath(part: string): NamePath {
      return typeof props.ruleIndex === 'number' ? ['rules', props.ruleIndex, part] : [props.ruleIndex, part];
    }
    const namePathItems = getPath('items');
    const items: EnableItemId[] = form.getFieldValue(namePathItems);

    if (!items)
      return;

    const parents: RuleGroup['parents'] = form.getFieldValue(getPath('parents'));
    filterItems(items.map(({ item_id }) => item_id), {
      regex: ['.*'],
      class: cls,
      hw_type: isClassLPAR(cls) ? hw_type : undefined,
      subsystem,
      parents: parents && {
        intersection: parents.intersection,
        item_ids: parents.items?.map(item => item.item_id),
        regex: parents.regex?.filter(r => r.regex && r.subsystem)
      }
    }).then(
      data => {
        const filteredItems: EnableItemId[] = items.map(item => ({ ...item, disabled: !data.includes(item.item_id) }));
        setField<EnableItemId[]>(namePathItems, filteredItems);
      },
      reason => Log.error('Failed to fetch items!', reason)
    );
  }, [props.ruleIndex, cls, subsystem, hw_type]);

  return (
    <RuleContext.Provider value={{ ...props, setField, makeChange, filterByParents }}>
      <div className="xm-rule">
        <Form.Item name={[props.ruleIndex, 'parents']} noStyle>
          <FilterModeParent />
        </Form.Item>
        <FilterMode />
      </div>
    </RuleContext.Provider>
  );
}

interface FilterModeParentProps {
  value?: RuleGroup['parents'];
  onChange?: (parents: RuleGroup['parents']) => void
}

function FilterModeParent({ value: parents }: FilterModeParentProps) {
  const { cls, subsystem, hw_type } = useContext(AllItemsContext);
  const { preview } = useContext(AdditionalApiParamsContext);
  const { ruleIndex, setField, makeChange, filterByParents, linuxExclude } = useContext(RuleContext);
  const [showPreview, contextHolderRegex] = usePreviewModal(linuxExclude);
  const invalidBaseProps = invalidHwProps({ cls, hw_type, subsystem });
  const [parentTypes, isLoadingParentTypes] = useParentTypes(cls, subsystem, hw_type);

  const handleFormFinish: FormProviderProps['onFormFinish'] = (_, { forms }) => {
    const { items: fItems }: FormParentSelectData = forms['selected-items'].getFieldsValue(true);
    const namePath: NamePath = typeof ruleIndex === 'number' ? ['rules', ruleIndex, 'parents', 'items'] : [ruleIndex, 'parents', 'items'];
    setField<Item[]>(namePath, fItems);
    filterByParents();
  };

  const [showModalSelectParent, contextHolderParent] = useSelectModal<Item[]>({
    onFormFinish: handleFormFinish,
  });

  const handleAdd = () => {
    if (!!!parents)
      return;

    if (parents.regex) {
      const regex: ParentRegex = {
        subsystem: isClassLPAR(cls) ? undefined : 'device',
        regex: '.*'
      };

      makeChange(rule => ({
        ...rule,
        parents: {
          ...rule.parents,
          regex: rule.parents.regex.length ? [
            ...rule.parents.regex,
            regex
          ] : [regex]
        }
      }));

      filterByParents()

      return;
    }

    if (parents.items) {
      showModalSelectParent({
        initialValues: { items: parents.items, subsystem: isClassLPAR(cls) ? undefined : 'device' },
        content: <ModalSelectParentItems parentTypes={parentTypes} />
      });
    }

    const tag = {
      tag_id: undefined,
      tag: undefined,
      subsystem: isClassLPAR(cls) ? undefined : 'device'
    };

    // Tags
    makeChange(rule => ({
      ...rule,
      parents: {
        ...rule.parents,
        tags: rule.parents.tags.length ? [
          ...rule.parents.tags,
          tag
        ] : [tag]
      }
    }));
  };

  const handleModeChange = ({ target: { value } }: RadioChangeEvent) => makeChange((group) => {
    function resetItems(): Pick<RuleGroup, 'items' | 'regex' | 'tags'> {
      if (group.items)
        return { ...group, items: [], regex: undefined, tags: undefined };

      return { ...group, items: undefined };
    }

    const newGroup: RuleGroup = {
      ...resetItems(),
      parents: {
        ...group.parents,
        items: undefined,
        regex: undefined,
        tags: undefined,
        [value as ModeType]: [],
      },
    };


    const subsystem = isClassLPAR(cls) ? undefined : 'device'

    if (value as ModeType === 'regex')
      newGroup.parents.regex = [{ subsystem, regex: '.*' }];

    if (value as ModeType === 'tags')
      newGroup.parents.tags = [{ tag: undefined, tag_id: undefined, subsystem }];

    return newGroup;
  });

  const handlePreview = () => {
    const baseData = {
      cls,
      subsystem,
      hw_type,
      parent: true,
      strictAcl: false,
      additionalApiParams: preview?.parents?.all || {},  // additionalApiParams.preview.parents.all
    };

    if (parents.tags) {
      showPreview({
        ...baseData,
        data: parents?.tags.map((r): ItemGroupPreview => ({
          subsystem: r.subsystem,
          hw_type: isClassLPAR(cls) ? hw_type : undefined,
          class: cls,
          tags: [r.tag_id],
          linuxExclude
        })).filter(data => !!data.subsystem),
      });

      return;
    }

    showPreview({
      ...baseData,
      data: parents?.regex.map((r): ItemGroupPreview => ({
        subsystem: r.subsystem,
        hw_type: isClassLPAR(cls) ? hw_type : undefined,
        class: cls,
        regex: [r.regex],
        linuxExclude
      })).filter(data => !!data.subsystem),
    });
  };

  const handleEnableParents = ({ target: { checked } }: CheckboxChangeEvent) => {
    makeChange((rule) => ({
      ...rule,
      parents: checked
        ? { ...defaultRuleParent }
        : undefined
    }));

    if (!checked)
      filterByParents();
  }

  function isDisabled() {
    return !parents || !parents.regex && !parents.items && !parents.tags || invalidBaseProps;
  }

  const isPreviewDisabled = !!!parents || !!parents?.items || parents.regex?.every(({ subsystem }) => !subsystem) || parents.tags?.every(({ tag_id }) => !tag_id) || invalidBaseProps;

  return (
    <div className='xm-rule-filter'>
      <div className={!!parents ? 'xm-rule-controllers' : 'xm-rule-controllers disabled'}>
        <Checkbox
          key={'is-parent'}
          className='xm-regex-checkbox header-bold'
          onChange={handleEnableParents}
          disabled={invalidBaseProps || isLoadingParentTypes || !parentTypes.length}
          checked={!!parents}
        >
          Filter by Parents
        </Checkbox>
        {isLoadingParentTypes && <Spinner size='small' style={{ margin: 'auto 0', position: 'inherit' }} />}
        <div className='xm-radio-select'>
          <Form.Item name={[ruleIndex, 'parents', 'intersection']} noStyle>
            <ConjunctionRadioSelect disabled={isDisabled()} onChange={() => filterByParents()} />
          </Form.Item>
          <ModeRadioSelect disabled={isDisabled()} regex={parents?.regex} items={parents?.items} onChange={handleModeChange} parent />
          <span>
            <ControlBtn
              icon={<EyeOutlined />}
              onClick={handlePreview}
              disabled={isPreviewDisabled}
            />
          </span>
        </div>
      </div>
      <ParentBox parentTypes={parentTypes} />
      {contextHolderRegex}
      {contextHolderParent}
      <div className={!!parents ? 'xm-rule-controllers-footer' : 'xm-rule-controllers-footer disabled'}>
        <Button size='small' onClick={handleAdd} disabled={isDisabled()}>Add Parent</Button>
      </div>
    </div>
  );
}

function ParentBox({ parentTypes }: { parentTypes: Option[] }) {
  const { cls } = useContext(AllItemsContext);
  const { ruleIndex, parents, filterByParents, setField } = useContext(RuleContext);

  if (!parents)
    return <></>;

  if (parents.items) {
    const handleCloseItem = (id: string) => () => {
      const namePath: NamePath = typeof ruleIndex === 'number' ? ['rules', ruleIndex, 'parents', 'items'] : [ruleIndex, 'parents', 'items'];
      setField<Item[]>(namePath, parents.items.filter(item => item.item_id !== id));
      filterByParents();
    };

    return (
      <div className='xm-form-no-message'>
        <Form.Item
          className='xm-select-wrapper'
          name={[ruleIndex, 'parents', 'items']}
          rules={[
            { required: true },
          ]}
        >
          <TagsList items={parents.items?.map(makeItemOfList(cls))} onClose={handleCloseItem} />
        </Form.Item>
      </div>
    );
  }

  if (parents.regex)
    return <RegexListParent parentTypes={parentTypes} />;

  if (parents.tags)
    return <TagListParent parentTypes={parentTypes} />;

  return <></>;
}

function FilterMode() {
  const { cls, subsystem, hw_type } = useContext(AllItemsContext);
  const { ruleIndex, parents, items, regex, tags, setField, makeChange, linuxExclude } = useContext(RuleContext);
  const { items: itemsParams } = useContext(AdditionalApiParamsContext);
  const [showPreview, contextHolderRegex] = usePreviewModal(linuxExclude);
  const invalidBaseProps = invalidHwProps({ cls, hw_type, subsystem });

  const handleFormFinish: FormProviderProps['onFormFinish'] = (_, { forms }) => {
    const { items }: { items: EnableItemId[] } = forms['selected-items'].getFieldsValue(true);
    const namePath = typeof ruleIndex === 'number' ? ['rules', ruleIndex, 'items'] : [ruleIndex, 'items'];
    setField<EnableItemId[]>(namePath, items);
  };

  const [showModalSelect, contextHolderSelect] = useSelectModal<EnableItemId[]>({
    onFormFinish: handleFormFinish,
  });

  const handlePreview = () => showPreview({
    cls,
    subsystem,
    hw_type,
    data: makePreviewApiData(cls, hw_type, subsystem, parents, regex, tags?.map(t => t.tag_id), linuxExclude),
    additionalApiParams: itemsParams?.select || {},
  });

  const handleAdd = () => {
    if (regex) {
      makeChange((rule) => ({
        ...rule,
        regex: [...rule.regex, '.*']
      }));

      return;
    }

    if (items) {
      showModalSelect({
        initialValues: { items },
        content: <ModalSelectItems />
      });

      return;
    }

    makeChange((rule) => ({
      ...rule,
      tags: [...rule.tags, { tag: undefined, tag_id: undefined }]
    }));
  };


  const handleModeChange = ({ target: { value } }: RadioChangeEvent) => makeChange((rule) => ({
    ...rule,
    items: undefined,
    [value as ModeType]: [],
    regex: value as ModeType == 'regex' ? ['.*'] : undefined,
    tags: value as ModeType == 'tags' ? [{ tag: undefined, tag_id: undefined }] : undefined,
  }));

  return (
    <div className='xm-rule-filter'>
      <div className='xm-rule-controllers'>
        <span className='header-bold'>Select Items</span>
        <div className='xm-radio-select'>
          <ModeRadioSelect onChange={handleModeChange} regex={regex} items={items} disabled={invalidBaseProps} />
          <span>
            <ControlBtn
              icon={<EyeOutlined />}
              onClick={handlePreview}
              className='rule'
              disabled={!!items || regex?.every(r => !!!r.length) || tags?.every(t => !!!t.tag_id) || invalidBaseProps}
            />
          </span>
        </div>
      </div>
      <ItemBox />
      {contextHolderRegex}
      {contextHolderSelect}
      <div className='xm-rule-controllers-footer'>
        <Button size='small' onClick={handleAdd} disabled={invalidBaseProps}>Add Item</Button>
      </div>
    </div>
  );
}

function ItemBox() {
  const { ruleIndex, items, regex, tags, setField } = useContext(RuleContext);

  if (items) {
    const namePath = typeof ruleIndex === 'number' ? ['rules', ruleIndex, 'items'] : [ruleIndex, 'items'];
    const handleCloseItem = (id: string) => () => {
      setField<EnableItemId[]>(namePath, items.filter(item => item.item_id !== id));
    };

    return (
      <div className='xm-form-no-message'>
        <Form.Item
          className='xm-select-wrapper'
          name={[ruleIndex, 'items']}
          rules={[
            { required: true },
            {
              validator: (_, values: EnableItemId[]) => {
                if (values.some(item => !item.disabled))
                  return Promise.resolve();

                return Promise.reject();
              }
            }
          ]}
        >
          <TagsBox onClose={handleCloseItem} />
        </Form.Item>
      </div>
    );
  }

  if (regex)
    return <RegexList />;

  if (tags)
    return <TagListItems />;

  return <></>;
}

interface ModeRadioSelectProps extends RadioGroupProps {
  items?: unknown[];
  regex?: unknown[];
  tags?: unknown[];
  parent?: boolean; // TODO: remove after cross-platform bug solved
}

export type ModeType = keyof Omit<RuleGroup, 'parents'>;

function ModeRadioSelect({ items, regex, tags, parent, ...rest }: ModeRadioSelectProps) {
  const { noTagMode, hw_type } = useContext(AllItemsContext);
  // TODO: remove disabled after cross-platform bug solved
  const options: CheckboxOptionType[] = noTagMode ? [
    { label: 'Select', value: 'items' },
    { label: 'RegEx', value: 'regex', disabled: hw_type === ClassKey.VIRTUALIZATION && parent },
  ] : [
    { label: 'Select', value: 'items' },
    { label: 'RegEx', value: 'regex' },
    { label: 'Tags', value: 'tags' }
  ];

  const value: ModeType = (!rest.disabled) ?
    items ? 'items' :
      regex ? 'regex' : 'tags'
    : undefined;

  return (
    <Radio.Group
      options={options}
      optionType="button"
      size="small"
      {...rest}
      value={value}
    />
  );
}

function ConjunctionRadioSelect(props: RadioGroupProps) {
  const options: CheckboxOptionType[] = [
    { label: 'AND', value: true },
    { label: 'OR', value: false },
  ];

  return (
    <Radio.Group
      options={options}
      optionType="button"
      size="small"
      {...props}
    />
  );
}

export function ControlBtn(props: ButtonProps) {
  const className = props.className ? `xm-btn-borderless xm-btn-controller ${props.className}` : 'xm-btn-borderless xm-btn-controller';

  return <Button
    size="small"
    {...props}
    className={className}
  />;
}

export const defaultRuleParent: RuleGroup['parents'] = {
  intersection: false,
  regex: undefined,
  items: [],
  tags: undefined
}

export const defaultRule: RuleGroup = {
  items: [],
  regex: undefined,
  parents: undefined,
};

export function filterItems(iids: string[], ...data: Parameters<typeof regexApi>): Promise<string[]> {
  return regexApi(...data).then(
    ({ data: { data } }) => iids.filter(id => data.some(item => item.item_id === id)),
    reason => { throw new Error(reason) }
  );
}

export function invalidHwProps({ cls, hw_type, subsystem }: HwProps) {
  return isClassLPAR(cls) ? !hw_type || !subsystem : !subsystem;
}

const makeItemOfList = (cls: string) => ({ label, item_id, subsystem }: Item) => {
  return isClassLPAR(cls) ? { key: item_id, title: `${subsystem}/${label}` } : { key: item_id, title: label };
};