import { TextResponseDTO } from "@dto/basicResponse.dto";
import { LOG_TYPE } from "@dto/constants/log.constants";
import { LogItem, LogsResponseDTO } from "@dto/log.dto";
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { RoutePath } from 'src/data/Routes';
import { menuItemsRight } from 'src/data/menu-items';
import useGetApiData from "src/hook/useGetApiData";
import { MenuNode } from "src/model/MenuNode";
import { Log } from 'src/service/Log';
import { GLOB } from 'src/util/Glob';
import AuditLog from "./AuditLog";
import LogViewer from "./LogViewer";
import LogExporter from "./LogExporter";

interface Props {
  children?: React.ReactNode;
}

const SetLogMenuNode = ({ children }: Props) => {
  const { pathname } = useLocation();
  const [component, setComponent] = useState<React.ReactNode>(children ?? <></>);
  const [data] = useGetApiData<LogItem[], LogsResponseDTO>("/api/log", "Get Logs failed", [], []);

  useEffect(() => {
    const path = pathname.split('/').slice(1);
    const utilNode = menuItemsRight.find(mn => mn.path === "/" + path[0]).children.find(mn => mn.path === "/" + path[1]);
    const setLogNode = () => {
      if (!data.length) {
        if (!children) {
          GLOB.selectedItem = utilNode.children[0];
          setTimeout(() => GLOB.setTree(utilNode));
        }
        return;
      }

      const logMenuNode: MenuNode[] = [];
      const map: Record<string, MenuNode> = {};
      for (const log of data) {
        if (!(log.type in LOG_TYPE))
          continue;

        const viewerData = (linesCount: number, controller: AbortController) => fetchData(log.url, linesCount, log.label, controller);

        if (!(logMenuNode.some(child => child.path === `/${log.type}-log`))) {
          if (log.type.charAt(0).toUpperCase() + log.type.slice(1) === log.label) {
            logMenuNode.push({
              key: `${log.type}-log`,
              title: log.label,
              path: `/${log.type}-log`,
              isLeaf: true,
              component: <LogViewer path={log.path} key={`log-${log.type}`} viewerData={viewerData} />
            });
            map[log.type] = logMenuNode[-1];

            continue;
          }

          logMenuNode.push({
            key: `${log.type}-log`,
            title: log.type.charAt(0).toUpperCase() + log.type.slice(1),
            path: `/${log.type}-log`,
            isLeaf: false,
            children: [{
              key: log.file,
              title: log.label,
              path: "/" + log.file,
              component: <LogViewer path={log.path} key={"log-" + log.label} viewerData={viewerData} />,
              isLeaf: true,
            }]
          });

          map[log.type] = logMenuNode[logMenuNode.length - 1];
          continue;
        }

        map[log.type].children.push({
          key: log.file,
          title: log.label,
          path: "/" + log.file,
          component: <LogViewer path={log.path} key={"log-" + log.label} viewerData={viewerData} />,
          isLeaf: true,
        });
      }

      for (const log of logMenuNode) {
        if (!log.isLeaf)
          log.children.sort((one, two) => (one.title > two.title ? 1 : -1));
      }

      logMenuNode.sort((one, two) => (one.children && !two.children ? -1 : 1))

      /// Audit
      logMenuNode.push({
        key: 'audit-log',
        title: 'Audit',
        path: RoutePath.LOG_AUDIT,
        component: <AuditLog />,
        isLeaf: true,
      });

      /// ui
      const viewerDataUi = (linesCount: number) => new Promise<string>((resolve) => {
        resolve(Log.ERRORS.isEmpty() ? '\n' : Log.ERRORS.toArray().reverse().slice(0, linesCount + 1).join('\n'))
      });

      logMenuNode.push({
        key: 'ui-log',
        title: 'UI',
        path: RoutePath.LOG_UI,
        isLeaf: true,
        component: <LogViewer key={"log-ui"} viewerData={viewerDataUi} />
      });

      logMenuNode.push({
        key: "support-log",
        title: "Support Log",
        path: RoutePath.LOG_SUPPORT,
        isLeaf: true,
        component: <LogExporter></LogExporter>
      });

      const childCopy = [...utilNode.children];
      const indexOfLogs = childCopy.map(node => node.key).indexOf(RoutePath.SET_LOGS);
      let logNode = { ...childCopy[indexOfLogs] };
      logNode.children = logMenuNode;
      childCopy[indexOfLogs] = logNode;
      utilNode.children = childCopy;

      if (!children) {
        const search = (index: number) => (mn: MenuNode) => mn.path === "/" + path[index];
        for (let index = 3; index < path.length; index++) {
          if (!path[index])
            break;

          logNode = logNode.children.find(search(index));
        }
        if (logNode.component)
          setComponent(logNode.component);

        GLOB.selectedItem = logNode;
      }

      GLOB.setTree({ ...utilNode });
    }

    setLogNode();
    GLOB.treeLoaded.reset();
  }, [data, pathname, children]);

  return <>{component}</>;
};

function fetchData(url: string, linesCount: number, type: string, controller: AbortController) {
  return axios.get<TextResponseDTO>(url + "?lines=" + linesCount, { signal: controller.signal }).then(
    ({ data: { data } }) => data ? data : '\n',
    reason => {
      Log.error(`Failed to get data about ${type}`, reason);
      return '';
    }
  );
}

export default SetLogMenuNode;