import { Export } from "@dto/pageResponse.dto";
import ExportIcon from "src/component/apiDownloader/ExportIcon";

interface Props {
  exportProp: Export;
}

const ExportContentIcon = ({ exportProp }: Props) => {
  return (
    <div className="export-content-icon-wrapper">
      <ExportIcon exportableTypes={{ XLSX: false, JSON: false, CSV: false }} export={exportProp} size="large" />
    </div>
  );
}

export default ExportContentIcon;