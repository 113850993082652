
export const GRAPH_INTERVAL = {
  day: 'day',
  week: 'week',
  month: 'month',
  year: 'year',
} as const;

export const CHART_TYPE = {
  area: 'area',
  line: 'line',
} as const;

export const METRIC_AGGREGATE = {
  items: 'items',
  sum: 'sum',
  max: 'max',
  avg: 'avg',
  wavg: 'wavg',
} as const;

export const BOX_TYPE = {
  FourGraphBox: 'FourGraphBox',
  RegroupedGraphBox: 'RegroupedGraphBox',
  SingleGraphBox: 'SingleGraphBox',
  SingleTimeRangeGraphBox: 'SingleTimeRangeGraphBox',
  MultiGraphBox: 'MultiGraphBox',
  PredictionGraphBox: 'PredictionGraphBox',
  HeatmapBox: 'HeatmapBox',
  StorageHeatmapBox: 'StorageHeatmapBox',
  TableBox: 'TableBox',
  TablePivotBox: 'TablePivotBox',
  TableGroupBox: 'TableGroupBox',
  TableHeaderGroupBox: 'TableHeaderGroupBox',
  TextBox: 'TextBox',
  HtmlTextBox: 'HtmlTextBox',
  DashboardBox: 'DashboardBox',
  SunburstBox: 'SunburstBox',
  TopBox: 'TopBox',
  CapacityTableBox: 'CapacityTableBox',
  TopologyBox: 'TopologyBox',
  TopologyRequestBox: 'TopologyRequestBox',
  PortHealthStatusBox: 'PortHealthStatusBox',
  OverviewBox: 'OverviewBox',
  CustomTopBox: 'CustomTopBox',
  RegroupButtonBox: 'RegroupButtonBox',
  MirrorBox: 'MirrorBox'
} as const;

export const TABLE_STYLE_CLASS = {
  center: 'center',
  bold: 'bold',
  thermometer: 'thermometer',
  healthStatus: 'healthStatus'
} as const;

export const TEXT_STYLE_CLASS = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  st: 'st'
} as const;

export const EXPORT_METHOD = {
  GET: 'GET',
  POST: 'POST',
} as const;

export const UNIT_PREFIX = {
  kilo: 'k',
  kibi: 'Ki',
  mega: 'M',
  mebi: 'Mi',
  giga: 'G',
  gibi: 'Gi',
  tera: 'T',
  tebi: 'Ti',
  peta: 'P',
  pebi: 'Pi',
  exa: 'E',
  exbi: 'Ei',
  zetta: 'Z',
  zebi: 'Zi',
  yotta: 'Y',
  yobi: 'Yi'
} as const;


export const MULTIPLIER = {
  base10: '1000',
  base2: '1024'
} as const;

export const TOPOLOGY_TYPE = {
  'switch-storage': 'switch-storage',
  'switch-switch': 'switch-switch',
  'switch-host': 'switch-host',
  'fabric': 'fabric'
} as const;

export const TOPOLOGY_ITEM_TYPE = {
  'switch': 'switch',
  'storage': 'storage',
  'host': 'host'
} as const;

export const ORIENTATION = {
  vertical: 'vertical',
  horizontal: 'horizontal'
} as const;
