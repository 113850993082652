import './CounterCircle.less';

interface Props {
  counter: number;
  color: string;
  xShift: number;
  yShift: number;
  children: React.ReactNode
}
const CounterCircle = ({ counter, color, xShift, yShift, children }: Props) => {
  return <div className="counter-notif-wrapper">
    <div className="counter-notif" style={{ transform: `translateX(${xShift}%) translateY(${yShift}%)` }}>
      <div className="circle-wrapper">
        <div className="circle" style={{ backgroundColor: `${color}`, borderRadius: counter < 99 ? "50%" : "38%" }}>{counter < 99 ? counter : '99+'}</div>
      </div>
    </div>
    {children}
  </div>;
};

export default CounterCircle;