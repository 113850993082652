import { BOX_TYPE, GRAPH_INTERVAL } from "@dto/constants/pageResponse.constants";
import { FindDashboardGraphDTO, FindGraph, FindGraphsDTO } from "@dto/dashboard.dto";
import { Box, GraphInterval, Metric, RegroupGraph } from "@dto/pageResponse.dto";
import { uniqueArrayFilterFce } from "@dto/util";
import { FC, createContext, useEffect, useMemo, useRef, useState } from "react";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { BoxPart } from "src/boxParts/BoxPart";
import { Graph } from "src/component/graph/Graph";
import { GraphPlotUtil } from "src/component/graph/GraphPlotUtil";
import { GraphType, StartEnd } from "src/component/graph/GraphTypes";
import { API_URL } from "src/data/Api";
import { Log } from "src/service/Log";
import { DateUtil } from "src/util/DateUtil";
import { GLOB } from "src/util/Glob";
import { postApi } from "src/util/apiCalls";
import { AbstractGraphPart } from './AbstractGraphPart';
import './GraphPart.less';

export class RegroupedGraphBox implements Box {
  type = BOX_TYPE.RegroupedGraphBox;
  interval: GraphInterval;
  graphs: RegroupGraph[];
  url: string;
}

export const RegroupedGraphIntervalLabel = {
  [GRAPH_INTERVAL.day]: 'Daily',
  [GRAPH_INTERVAL.week]: 'Weekly',
  [GRAPH_INTERVAL.month]: 'Monthly',
  [GRAPH_INTERVAL.year]: 'Yearly'
};

export interface RegroupProps {
  /**
   * Zoom start/end in unix seconds
   */
  readonly zoomRange: Readonly<StartEnd>;
  setZoomRange(range: StartEnd): void;
  /**
   * Keys of graph items hidden in plot
   */
  readonly hiddenItems: readonly string[];
  setHiddenItems(itemKeys: string[]): void;
}

export const RegroupContext = createContext<RegroupProps>({
  zoomRange: undefined,
  setZoomRange: () => undefined,
  hiddenItems: [],
  setHiddenItems: () => undefined
});

const RegroupedGraphPart: FC<BoxPart<RegroupedGraphBox>> = props => {

  const [state] = useState({ intervalTimeout: 0 });
  const colorMap = useMemo(() => AbstractGraphPart.mapColors(props.box.graphs?.[0]?.uuids,
    props.box.graphs?.some(rg => rg.metrics?.length > 1) ? props.box.graphs.flatMap(rg => rg.metrics).filter(uniqueArrayFilterFce((a, b) => a.metric === b.metric))
      : [{ metric: GraphPlotUtil.SINGLE_ITEM } as Metric], props.box.graphs?.[0].aggregated).colorPalette, []);
  const [range, setRange] = useState<StartEnd>(() => initInterval());
  const [zoomRange, setZoomRange] = useState<StartEnd>();
  const [hiddenItems, setHiddenItems] = useState<string[]>([]);
  const regroupedProps = useMemo(() => ({ zoomRange, setZoomRange: setZoomRangeIf, hiddenItems, setHiddenItems: setHiddenItemsIf }),
    [zoomRange, setZoomRange, hiddenItems, setHiddenItems]);
  const [graphsInDash, setGraphsInDash] = useState<Record<string, FindGraph[]>>({});
  const aborter = useRef<AbortController>();

  useEffect(() => {
    aborter.current = new AbortController();
    updateStartEnd();
    const promises: Promise<void>[] = [];
    props.box.graphs.forEach(graph => {
      promises.push(findInDash(graph.tab || graph.label, graph.url || props.box.url));
    });
    Promise.all(promises).then(() => {
      setGraphsInDash({ ...graphsInDash });
    }, reason => console.error('Failed to find graphs in dashboard!', reason));
    return () => {
      clearTimeout(state.intervalTimeout);
      aborter.current.abort();
    }
  }, []);

  function initInterval() {
    if (props.box.interval === 'day') {
      const se = DateUtil.initShort();
      return { start: se.startDay, end: se.endShort };
    } else {
      const se = DateUtil.initLong();
      switch (props.box.interval) {
        case 'week':
          return { start: se.startWeek, end: se.endLong };
        case 'month':
          return { start: se.startMonth, end: se.endLong };
        case 'year':
          return { start: se.startYear, end: se.endLong };
        default:
          console.error('Unknown interval ', props.box.interval);
      }
    }
  }

  function updateStartEnd() {
    clearTimeout(state.intervalTimeout);
    state.intervalTimeout = window.setTimeout(() => {
      setRange(initInterval());
    }, props.box.interval === 'day' ? GLOB.TIMEOUT_SHORT : GLOB.TIMEOUT_LONG);
  }

  function findInDash(tabName: string, url: string) {
    return postApi<FindGraphsDTO, FindDashboardGraphDTO>(API_URL.DASHBOARD_FIND_GRAPH,
      { tab: tabName, url }, { signal: aborter.current.signal }).then(response => {
        const found = response.data.data;
        const key = tabName + url;
        graphsInDash[key] = [...(graphsInDash[key] || []), ...found];
      }, reason => Log.error('Failed to get graph dashboards!', reason));
  }

  function setZoomRangeIf(range: StartEnd) {
    if (zoomRange?.start === range?.start && zoomRange?.end === range?.end) return;
    setZoomRange(range);
  }

  function setHiddenItemsIf(itemKeys: string[]) {
    if (itemKeys.length === hiddenItems.length) {
      for (const ik of itemKeys) {
        if (hiddenItems.includes(ik))
          continue;
        setHiddenItems(itemKeys);
        return;
      }
      return;
    }
    setHiddenItems(itemKeys);
  }

  return <div className="text-center">
    <RegroupContext.Provider value={regroupedProps}>
      {props.box.graphs.map(graph => {
        const dashKey = (graph.tab || graph.label) + (graph.url || props.box.url);
        return <div key={graph.graphLabel || graph.label} className="xm-graph-part">
          <LazyLoadComponent >
            <Graph type={GraphType.regular} box={{ ...graph }} end={range.end} start={range.start}
              interval={props.box.interval} title={graph.graphLabel || graph.label}
              common={{
                dashUrl: graph.url || props.box.url, tabName: RegroupedGraphIntervalLabel[props.box.interval] || graph.tab || graph.label,
                colorMap, dashboardGraphs: graphsInDash[dashKey],
                setDashboardGraphs: (fgs) => setGraphsInDash(prev => {
                  prev[dashKey].splice(0, graphsInDash[dashKey].length, ...fgs);
                  return { ...prev };
                })
              }} />
          </LazyLoadComponent>
        </div>;
      })}
    </RegroupContext.Provider>
  </div>;

}

export default RegroupedGraphPart;