import { DeleteOutlined, EditOutlined, InfoCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { ServerGroup } from "@dto/serverGroup.dto";
import { Button, Form, Popconfirm } from "antd";
import { BodyRowProps } from 'rc-table/lib/Body/BodyRow.js';
import { Attributes, PropsWithChildren, ReactElement, useContext, useState } from "react";
import { ColProps, GridScope, IGridEntry } from "src/component/grid/Grid";
import { Log } from "src/service/Log";
import ExpandPreview from "../../sg/ExpandPreview";
import { deleteServerGroupApi } from "./ServerGroupAPI";
import { ServerGroupContext } from "./ServerGroupContext";



function RowWrapper({ children, formData }: PropsWithChildren<{ formData: ServerGroup }>) {
  return (
    <Form
      name={formData.server_group_id}
      component={false}
      initialValues={{ ...formData }}
    >
      {children}
    </Form>
  )
}


type Children = ReactElement<BodyRowProps<ServerGroup>> & Attributes;
const BodyWrapper = (props: PropsWithChildren) => {
  const record = { ...props };
  if (props.children && Array.isArray(props.children) && props.children.length > 1 && Array.isArray(props.children[1])) {
    record.children = [...props.children];
    record.children[1] = (props.children[1] as Children[]).map(c => {
      return (
        <RowWrapper key={c.props.record.server_group_id + c.props.record.user_id} formData={c.props.record}>
          {c}
        </RowWrapper>
      )
    });
  }

  return <tbody {...record} />;
}


const ServerGroupTable = () => {

  const [gs] = useState(() => new GridScope())
  const [expandRowKeys, setExpandRowKeys] = useState<string[]>([]);

  const expandRender = (sg: ServerGroup) => (
    <div className="prewie" >
      <ExpandPreview devices={sg.items} selectAble={false} showIcon={false} withoutTitle />
    </div>
  );

  const onExpandIconClick = (record: ServerGroup) => () => {
    expandRowKeys.includes(record.server_group_id) ? setExpandRowKeys([]) : setExpandRowKeys([record.server_group_id]);
  }

  const columns: ColProps<ServerGroup & IGridEntry>[] = [
    {
      title: 'Group Name',
      dataIndex: 'label',
      key: 'label',
      minWidth: 80,
      filter: { field: ["label"], type: "string" },
    },
    { /// Detail
      title: "Detail",
      key: "detail",
      align: "center",
      render: (record: ServerGroup) => (
        <Button className="ant-btn-link" title="Detail" icon={<InfoCircleOutlined />} onClick={onExpandIconClick(record)} />
      ),
    },
    {
      title: '',
      align: 'center',
      render: (_, record: ServerGroup) =>
        <>
          <Button
            type='text'
            title={`Edit ${label} group`}
            className='hover-info'
            icon={<EditOutlined />}
            onClick={() => {
              setServerGroupInEdit(record)
              setIsFormOpen(true);
            }}
          />
          <Popconfirm
            title={
              <>
                Delete{" "}
                <span style={{ fontWeight: "bold" }}>
                  {record.label.length > 10
                    ? `${record.label.slice(0, 3)}...${record.label.slice(
                      record.label.length - 6,
                      record.label.length
                    )}`
                    : record.label}{" "}
                </span>
                ?
              </>
            }
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            onConfirm={() => handleDelete(record.server_group_id)}
          >
            <Button
              type='text'
              title={`Delete ${label} group`}
              className='hover-danger'
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </>
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      minWidth: 80,
      filter: { field: ["description"], type: "string" },
      trim: true,
      trimLength: 50
    },
  ]

  const handleDelete = async (server_group_id: string) => {

    try {
      await deleteServerGroupApi(server_group_id);
      setServerGroups((prev) => prev.filter(sg => sg.server_group_id !== server_group_id));
      Log.success(`Deleted ${label} group`)
    } catch (error) {
      Log.error(`Deleting ${label} group dailed`, error);
    }

  };

  const {
    setIsFormOpen,
    setServerGroups,
    serverGroups,
    setServerGroupInEdit,
    label
  } = useContext(ServerGroupContext);

  return (
    <div className="xm-sg">
      <Button
        onClick={() => {
          setIsFormOpen(true);
        }}
        className="sg-btn sg-btn-add"
      >Add {label} Group</Button>
      <gs.Grid
        style={{ minWidth: "200px" }}
        className='xm-table-fit'
        dataSource={serverGroups}
        rowKey={(record: ServerGroup) => record.server_group_id}
        expandedRowKeys={expandRowKeys}
        expandedRowRender={expandRender}
        expandIconColumnIndex={-1} /// hide expander icon
        loading={false}
        columns={columns}
        components={{
          body: {
            wrapper: BodyWrapper
          }
        }}
      />
    </div>
  )
}

export default ServerGroupTable