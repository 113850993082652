/**
 * Compares string with 'true'
 * @param value boolean or string to parse
 * @returns boolean
 */
export function parseBool(value) {
  return typeof value === 'boolean' ? value : value == 'true';
}

/**
   * Returns array filter with provided equals comparison
   * @param equals
   * @returns
   */
export function uniqueArrayFilterFce<T>(equals: (a: T, b: T) => boolean) {
  return (value: T, index: number, array: T[]) => array.findIndex(val => equals(value, val)) === index;
}