import { ClassKey } from "@const/class.constants";
import { FC, useEffect, useState } from "react";
import { useLocation, useMatch } from "react-router-dom";
import { RoutePath } from "src/data/Routes";
import NavigateRoute, { NavRouteProps } from "./NavigateRoute";
;

export const RouteType: FC = () => {
  const { search } = useLocation();
  const match = useMatch(RoutePath.MATCH_TYPE);
  const [navProps, setNavProps] = useState<NavRouteProps>();

  useEffect(() => {
    updateProps();
  }, [match.params, search]);

  function updateProps() {
    const cls = match.params.class;
    let type = match.params.type;
    let item = match.params.item;
    if (ClassKey.STORAGE !== cls && !item) {
      item = type;
      type = null;
    }
    setNavProps({ clsName: cls, allType: RoutePath.TYPE, typeOrDevice: type, itemName: item, search });
  }

  return navProps && <NavigateRoute {...navProps} />;
}