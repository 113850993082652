import { Item } from "@dto/architecture.dto";
import { Form } from "antd";
import Select, { DefaultOptionType, SelectProps } from "antd/lib/select";
import { PropsWithChildren, useState } from "react";
import { GLOB } from "src/util/Glob";
import TagsList, { TagItem } from "../comp/TagsList";
import TreeTitle from "../comp/TreeTitle";

interface Props<FormItem> extends PropsWithChildren {
  makeItemOfList: (item: FormItem) => TagItem,
  makeLabel: (item: Item) => string | JSX.Element,
  isLoading: boolean,
  treeItems: Item[],
  handleCheckItem: (item: Item) => FormItem
  treeSelectProps?: SelectProps
}

function ModalSelectItems<FormItem extends { item_id: string }, FormInstance extends { items: FormItem[] }>({
  makeItemOfList,
  makeLabel,
  isLoading,
  treeItems,
  children,
  handleCheckItem,
  treeSelectProps,
}: Props<FormItem>) {
  const form = Form.useFormInstance<FormInstance>();
  const items = Form.useWatch<FormItem[]>('items', { form, preserve: true }); // ! in ant.design v5 fully
  const [openDropdown, setOpenDropdown] = useState(false);
  const handleRemoveItem = (id: string) => () => form.setFieldValue('items', items.filter(item => item.item_id !== id));
  const [searchValue, setSearchValue] = useState('');
  // ! select dropdown is closing when selected same item twice in a row
  // const handleSelect = (item: Item) => (e: CheckboxChangeEvent) => {
  //   form.setFieldValue('items', e.target.checked ? [...items, handleCheckItem(item)] : items.filter(it => item.item_id !== it.item_id));
  //   e.preventDefault();
  // };

  function makeTreeObj(item: Item): DefaultOptionType {
    return {
      value: item.item_id,
      search: item.label,
      label: <TreeTitle
        checked={items?.some(({ item_id }) => item_id === item.item_id)}
        label={makeLabel(item)}
      // onChange={handleSelect(item)} // ! select dropdown is closing when selected same item twice in a row
      />
    };
  }

  const handleDropdownClick: React.MouseEventHandler<HTMLDivElement> = e => {
    const cls = (e.target as Element).getAttribute('class');
    if (!cls || cls.includes('ant-checkbox-input') || cls.includes('xm-item-root-parent'))
      return;

    e.preventDefault();
  };

  const handleBlur = () => setOpenDropdown(false);
  const handleClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    setOpenDropdown(true);
    e.preventDefault(); // this will prevent additional blur call in some browsers (bug)
  };

  const handleSelect = (_: string, { value: id }: DefaultOptionType) => {
    const selectedItem = treeItems.find(item => item.item_id === id);
    form.setFieldValue('items', items.some(i => i.item_id == id) ? items.filter(it => id !== it.item_id) : [...items, handleCheckItem(selectedItem)]);
  };

  const handleSearch = (value: string) => setSearchValue(value);

  const options = treeItems.map(makeTreeObj);

  return <>
    <div className='xm-select-controls'>
      {children}
      <Select
        className="xm-tree-select"
        showSearch
        searchValue={searchValue}
        onSearch={handleSearch}
        open={openDropdown}
        {...treeSelectProps}
        value={null} // it keeps placeholder, after item select
        loading={isLoading}
        size='middle'
        style={{ marginRight: '9em' }}
        placeholder="Search"
        optionFilterProp="search"
        options={options}
        onClick={handleClick}
        onBlur={handleBlur}
        onSelect={handleSelect}
        dropdownRender={menu => (
          <div onClick={handleDropdownClick}>
            {menu}
          </div>
        )}
      />
    </div>
    <TagsList
      items={items?.map(makeItemOfList).sort((a, b) => GLOB.naturalSort(a.title, b.title))}
      onClose={handleRemoveItem}
    />
  </>;
}

export default ModalSelectItems;