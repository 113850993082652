import { DatePicker } from "antd"
import { RangePickerDateProps } from "antd/lib/date-picker/generatePicker"
import moment, { Moment } from "moment"
import { RangeValue } from 'rc-picker/lib/interface'
import { FC } from "react"

const { RangePicker } = DatePicker

export const RANGE_DATE_PICKER_DEFAULT_RANGES = {
  'Last Hour': [moment().subtract(1, 'hour'), moment()] as RangeValue<Moment>,
  'Last Day': [
    moment().subtract(1, 'day').startOf('day'),
    moment().subtract(1, 'day').endOf('day'),
  ] as RangeValue<Moment>,
  'Last Week': [
    moment().subtract(1, 'week').startOf('day'),
    moment().endOf('day'),
  ] as RangeValue<Moment>,
  'Last Year': [
    moment().subtract(1, 'year').startOf('day'),
    moment().endOf('day'),
  ] as RangeValue<Moment>,
} as const;

const RangeDatePicker: FC<RangePickerDateProps<Moment>> = (props) => {
  return (
    <RangePicker
      ranges={RANGE_DATE_PICKER_DEFAULT_RANGES}
      showTime={{ format: 'HH:mm' }}
      format="YYYY/MM/DD HH:mm"
      {...props}
    />
  )
}

export default RangeDatePicker