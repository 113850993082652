import { FC, useEffect, useState } from "react";
import { useLocation, useMatch } from "react-router-dom";
import { RoutePath } from "src/data/Routes";
import { GLOB } from "src/util/Glob";
import NavigateRoute, { NavRouteProps } from "./NavigateRoute";

export const RouteGroup: FC = () => {
  const { search } = useLocation();
  const matchTop = useMatch(RoutePath.MATCH_GROUP_TOP);
  const matchAll = useMatch(RoutePath.MATCH_GROUP_TOP + '/' + RoutePath.MATCH_ITEM_ONLY);
  const [navProps, setNavProps] = useState<NavRouteProps>();

  useEffect(() => {
    updateProps();
  }, [matchTop?.params, matchAll?.params, search]);

  function updateProps() {
    const match = matchAll || matchTop;
    const cls = match.params.class;
    let group = decodeURI(match.params.group);
    let type = match.params.type;
    let item = matchAll?.params.item;
    const groups = GLOB.menuService.storagesBy.group;
    if (!type && groups.children?.some(mn => mn.pageType === group)) {
      item = group;
      group = null;
    } else if (!item) {
      item = type;
      type = null;
    }
    setNavProps({ clsName: cls, allType: RoutePath.GROUP, groupName: group, typeOrDevice: type, itemName: item, search });
  }

  return navProps && <NavigateRoute {...navProps} />;
}