import React, { FC } from 'react'


const LogExporterDescription: FC = () => {
  return (
    <>
      <h2>Support Logs</h2>
      <p>Use this feature only if asked by Xormon Support Team.</p>
      <p>Use form below to export all logs. Paste SQL command  to the form below only if instructed by support team.</p>
      <p>Export might take a few minutes.<br />
        It only exports logs in tar.gz format and saves it into your local Download folder.<br />
        You can review it before sending to us via the <a href="https://upload.xormon.com/" target="_blank">upload form</a>.<br /></p>
    </>
  )
}

export default LogExporterDescription