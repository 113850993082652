import { AlertsResponseDTO, CreateAlertGroupDTO } from '@dto/alerting.dto';
import { ClassesResponseDTO, HwTypesResponseDTO, SubsystemsResponseDTO } from '@dto/architecture.dto';
import { BasicResponseDTO, TextResponseDTO } from '@dto/basicResponse.dto';
import { CustomGroupItemDTO, CustomGroupsResponseDTO } from '@dto/customGroup.dto';
import axios, { AxiosResponse } from 'axios';
import { getApi, postApi, putApi } from 'src/util/apiCalls';
import { API_URL } from '../../../data/Api';
import { Log } from '../../../service/Log';

export const alertingAbortCtrl: { current?: AbortController } = {};

//TODO: make separate glob promise
export function getAllSubsystems(type?: string) {
  const promise = axios.get<SubsystemsResponseDTO>(type ? API_URL.ARCHITECTURE + `/subsystems?type=${type}` : API_URL.ARCHITECTURE + `/subsystems`);
  promise.catch(reason => Log.error('Failed to get subsystems!', reason));
  return promise;
}

export async function getCustomGroups() {
  const promise = getApi<CustomGroupsResponseDTO>("/api/custom_group/v1/custom_groups", alertingAbortCtrl.current.signal);
  promise.catch(reason => Log.error('Failed to get custom groups!', reason));
  return promise;
}

export async function getCustomGroupItems(id: string) {
  const promise = getApi<CustomGroupItemDTO>(API_URL.CUSTOM_GROUP + '/custom_groups/' + id + '/items', alertingAbortCtrl.current.signal);
  promise.catch(reason => Log.error('Failed to get items of custom group ' + id, reason));
  return promise;
}

export async function getClasses() {
  const promise = axios.get<ClassesResponseDTO>(API_URL.ARCHITECTURE + '/classes?active=true&skip_acl=true');
  promise.catch(reason => Log.error('Failed to get classes!', reason));
  return promise;
}

export async function getAclHWtypes() {
  const promise = getApi<HwTypesResponseDTO>(API_URL.ARCHITECTURE + '/hw_types', alertingAbortCtrl.current.signal);
  promise.catch(reason => Log.error('Failed to get HW types!', reason));
  return promise;
}

// export async function getMetricsCg(id: string) {
//   const promise = getApi<MetricsResponseDTO>("/api/custom_group/v1/custom_groups/" + id + "/metrics",alertingAbortCtrl.current.signal);
//   promise.catch(reason => Log.error('Failed to get CG metrics!', reason));
//   return promise;
// }

export async function postAlertGroup(postData: CreateAlertGroupDTO) {
  const promise = postApi<AlertsResponseDTO, CreateAlertGroupDTO[]>("/api/alerting/v1/alertGroups", [postData]);
  promise.catch(reason => Log.error('Failed to create new alert group', reason));
  return promise;
}

export async function putAlertGroup(cag: CreateAlertGroupDTO) {
  const promise = putApi<BasicResponseDTO, CreateAlertGroupDTO>(API_URL.ALERTING + '/alert_group/' + cag.alerting_group_id, cag);
  promise.catch(reason => Log.error('Failed to update alert group', reason));
  return promise;
}

export function getAlertGroups() {
  const promise = getApi<AlertsResponseDTO>(API_URL.ALERTING + '/alertGroups', alertingAbortCtrl.current.signal);
  promise.catch(reason => Log.error('Failed to get alert groups!', reason));
  return promise;
}

export function deleteAlertGroups(alertGroupIds: string[]) {
  const promise = axios.delete<string[], AxiosResponse<BasicResponseDTO>>(API_URL.ALERTING + '/alertGroups', { data: alertGroupIds });
  promise.catch(reason => Log.error('Failed to delete alert groups!', reason));
  return promise;
}

export async function getLogs(lines: number) {
  const promise = getApi<TextResponseDTO>("/api/log/alerting?lines=" + lines, alertingAbortCtrl.current.signal);
  promise.catch(reason => Log.error('Failed to get alerting logs!', reason));
  return promise;
}

