import { TIMESERIES_TOP_ORDER } from '@const/timeseriesRequest.contants';
import { MetricAggregate } from "./pageResponse.dto";
export type TimeseriesTopOrder = typeof TIMESERIES_TOP_ORDER[keyof typeof TIMESERIES_TOP_ORDER];

export class TimeseriesRequestDTO {
  uuid: Array<string>;
  metrics: string[];
  start: number;
  end: number;
  func?: MetricAggregate;
  path?: string
}

export class PredictionBase {
  item_id: string;
  command: string;
  frequency: string;
  interval: 'year' | 'month';
  max: number;
}

export class PredictionRequestDTO extends PredictionBase {
  metric: string;
  other_metrics: string[];
}

