import 'animate.css';
import React, { FC, HTMLAttributes, useState } from 'react';
import './DivAnimated.less';

export const DivAnimated: FC<HTMLAttributes<HTMLDivElement> & { disabled?: boolean }> = (props) => {
  const [classes, setClasses] = useState('');

  return (
    <div
      onClick={(event) => {
        if (props.disabled) return;
        setClasses('div-animated');
        if (props.onClick) props.onClick(event);
      }}
      className={classes}
      onAnimationEnd={() => setClasses('')}
    >
      {props.children}
    </div>
  );
};
