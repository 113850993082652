export const STATUS = {
  CRITICAL: 'error',
  WARNING: 'warning',
  UNKNOWN: 'unknown',
  IGNORED: 'ignored',
  OK: "ok",
  UNDEFINED: "undefined"
} as const;

export const COLORS = {
  [STATUS.OK]: '#8FD400',
  [STATUS.CRITICAL]: '#e32636',
  [STATUS.WARNING]: '#ffa500',
  [STATUS.UNKNOWN]: '#94c5f9',
  [STATUS.IGNORED]: '#d3d3d3',
  [STATUS.UNDEFINED]: '#d3d3d3',
} as const;

export const HEALTH_STATUS_ITEM_ID = 'health-status';
export const HEATMAP_ITEM_ID = 'heatmap';