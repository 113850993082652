import { CapacityTableColumn } from "@dto/pageResponse.dto";
import { Table } from "antd";
import { ColProps, IGridEntry } from "src/component/grid/Grid";

interface Props<RecordType> {
    data: CapacityTableColumn[];
    cols: ColProps<RecordType & IGridEntry> | ColProps<RecordType & IGridEntry>[];
}

export function TableSummary<RecordType>({ data, cols }: Props<RecordType>) {
    return (
        <>
            <Table.Summary.Row>
                {
                    data.map((col, idx) => {
                        return (
                            <Table.Summary.Cell index={idx} {...cols[idx].props}>{cols[idx].props.render(col.value, data, idx)} </Table.Summary.Cell>
                        );
                    })
                }
            </Table.Summary.Row>
        </>
    );
};