import { Item, ServerGroup } from "@dto/serverGroup.dto";
import { UniversalGroupHwInfo } from "@dto/universalGroupHwInfo.dto";
import { Dispatch, SetStateAction, createContext } from "react";

interface iServerGroupContext {
    isFormOpen: boolean;
    setIsFormOpen: Dispatch<SetStateAction<boolean>>;
    servers: Item[];
    setServers: Dispatch<SetStateAction<Item[]>>,
    serverGroups: ServerGroup[],
    setServerGroups: Dispatch<SetStateAction<ServerGroup[]>>,
    serverGroupInEdit: ServerGroup,
    setServerGroupInEdit: Dispatch<SetStateAction<ServerGroup>>
    hwTypes: UniversalGroupHwInfo[],
    label: string
}

export const ServerGroupContext = createContext<iServerGroupContext>(null)
