import { GraphTableProperty } from "@dto/pageResponse.dto";
import { TimeseriesMetadata } from "@dto/timeseriesResponse.dto";
import { RegroupContext, RegroupProps } from "@parts/graph/RegroupedGraphPart";
import { TablePaginationConfig, Tooltip } from "antd";
import ColumnGroup from "antd/lib/table/ColumnGroup";
import { FilterValue, SortOrder, SorterResult, TableCurrentDataSource } from "antd/lib/table/interface";
import _ from "lodash";
import { PlotData } from "plotly.js";
import { getPathValue } from 'rc-table/lib/utils/valueUtil';
import { FC, createElement, useCallback, useContext, useEffect, useRef, useState } from "react";
import { GLOB } from "src/util/Glob";
import { TextUtil } from "src/util/TextUtil";
import { UnitUtil } from "src/util/UnitUtil";
import { nameof } from 'ts-simple-nameof';
import { GridScope, IGridEntry } from "../grid/Grid";
import { LinkXM } from "../link/LinkXM";
import { GraphPlotUtil } from "./GraphPlotUtil";
import { CommonGraphProps, GraphProps, GraphState, KTM, PlotCmp } from "./GraphTypes";

const DBL_CLICK_DELAY = 400;

const cols = [
  {
    key: 'avg',
    title: 'Avg',
  },
  {
    key: 'max',
    title: 'Max',
  },
];

let sortOrder: SortOrder;
const numericSort = (metric: string, col: keyof TimeseriesMetadata | string, gpu: GraphPlotUtil) => {
  return (l: KTM, r: KTM) => {
    if (l.isOthers()) {
      return sortOrder === 'ascend' ? 1 : -1;
    }
    if (r.isOthers()) {
      return sortOrder === 'ascend' ? -1 : 1;
    }

    const mCmp = gpu.cmpKtmIfMetric(l, r);
    if (mCmp != null) return 0;

    const left = l.data[metric][col];
    const right = r.data[metric][col];
    if (left == null) {
      return sortOrder === 'ascend' ? 1 : -1;
    }
    if (right == null) {
      return sortOrder === 'ascend' ? -1 : 1;
    }
    return left - right;
  }
};

function stringSort(selector: (props: KTM) => unknown, gpu: GraphPlotUtil) {
  return (l: KTM, r: KTM) => {
    if (l.isOthers()) {
      return sortOrder === 'ascend' ? 1 : -1;
    }
    if (r.isOthers()) {
      return sortOrder === 'ascend' ? -1 : 1;
    }

    const mCmp = gpu.cmpKtmIfMetric(l, r);
    if (mCmp != null) return 0;


    const path = nameof(selector);
    const a = _.get(l, path) as string;
    if (a == null) return sortOrder === 'ascend' ? 1 : -1;
    const b = _.get(r, path) as string;
    if (b == null) return sortOrder === 'ascend' ? -1 : 1;
    return GLOB.naturalSort(a, b);
  };
}

interface GraphTableBaseProps {
  data: Partial<PlotData>[];
  tableData: KTM[];

  setPlotVisible: (visible: boolean) => void;
  setData(data: Partial<PlotData>[]): void;
  setTableData: (tableData: KTM[]) => void;
  assignTickFormat: (tblData: KTM[], plotData?: Partial<PlotData>[]) => void;
}

interface GraphTableProps extends GraphTableBaseProps {
  graphPlotData: GraphPlotUtil;
  state: GraphState;
  //box: GraphBase;
  graphProps: GraphProps
}

const legendClickHolder: LegendClickHolder = { mouseDownTime: 0, numClicks: 0, timeout: 0 };

interface ColorSquareParams extends GraphTableBaseProps {
  plotlyRef: PlotCmp;
  tblState: { updateTimeout: number };
  metricsCount: number;
  regroupedProps?: RegroupProps;
  assignFixedUIDs?: () => void;
}

function onSquareInRow(record: KTM, metricsCount: number) {
  if (record.updated) return false;
  record.updateCount++;
  if (record.updateCount >= metricsCount)
    record.updated = true;
  return true;
}

export function colorSquare(ktm: KTM, metricName: string, params: ColorSquareParams) {

  if (!params.plotlyRef?.el) return;

  function reloadPlot() {
    // Workaround for plotly missing trace on restore visibility
    params.setPlotVisible(false);
    setTimeout(() => {
      params.setPlotVisible(true);
      setTimeout(() => {
        params.setTableData([...params.tableData]);
      });
    });
  }

  const matchTrace = GraphPlotUtil.traceMatcher(ktm.uid, metricName);

  const fullData = params.plotlyRef.el._fullData;
  if (fullData) {
    const pd = fullData.find(matchTrace);
    if (pd?.line?.color) {
      const color = pd.line.color as string;
      const dat = params.data.find(matchTrace);
      if (!dat) return;
      const regroupedProps = params.regroupedProps;
      const square = <Tooltip mouseLeaveDelay={0} mouseEnterDelay={0.2} title={<span>Click to show/hide this trace<br />Doubleclick to show/hide other traces.</span>}>
        <div onMouseDown={event => {
          const newMouseDownTime = Date.now();
          if (newMouseDownTime - legendClickHolder.mouseDownTime < DBL_CLICK_DELAY) {
            legendClickHolder.numClicks += 1;
          } else {
            legendClickHolder.numClicks = 1;
            legendClickHolder.mouseDownTime = newMouseDownTime;
          }
        }} onMouseUp={event => {
          if (Date.now() - legendClickHolder.mouseDownTime > DBL_CLICK_DELAY) {
            legendClickHolder.numClicks = Math.max(legendClickHolder.numClicks - 1, 1);
          }
          if (legendClickHolder.numClicks === 1) {
            window.clearTimeout(legendClickHolder.timeout);
            legendClickHolder.timeout = window.setTimeout(() => {
              if (dat.visible === 'legendonly') {
                dat.visible = true;
                if (params.data.some(pd => pd.fill || pd.stackgroup))
                  reloadPlot();
                regroupedProps?.setHiddenItems(regroupedProps?.hiddenItems.filter(um => um !== ktm.uid));
              } else {
                dat.visible = 'legendonly';
                regroupedProps?.setHiddenItems([...(regroupedProps?.hiddenItems || []), ktm.uid]);
              }
              params.assignFixedUIDs?.();
              params.assignTickFormat(params.tableData, params.data);
              params.setData([...params.data]);
              params.setTableData([...params.tableData]);

            }, DBL_CLICK_DELAY);
          } else if (legendClickHolder.numClicks === 2) {
            window.clearTimeout(legendClickHolder.timeout);
            if (dat.visible === true && !params.data.some(ppd => ppd.visible === 'legendonly')) {
              params.data.forEach(ppd => ppd.visible = 'legendonly');
              dat.visible = true;
              regroupedProps?.setHiddenItems(params.data.filter(pd => pd !== dat).map(pd => pd.meta?.uid));
            } else {
              params.data.forEach(ppd => ppd.visible = true);
              if (params.data.some(pd => pd.fill || pd.stackgroup))
                reloadPlot();
              regroupedProps?.setHiddenItems([]);
            }
            params.assignFixedUIDs?.();
            const resettedTbl = GraphPlotUtil.resetUpdates(params.tableData);
            params.assignTickFormat(resettedTbl);
            params.setTableData(resettedTbl);
            params.setData([...params.data]);
          }
        }}
          onClick={event => event.stopPropagation()}
          style={{
            width: '1em', height: '1em', backgroundColor: color,
            border: '1px solid black',
            opacity: dat.visible === true ? 1 : 0.4,
            cursor: 'pointer'
          }}></div></Tooltip>;
      onSquareInRow(ktm, params.metricsCount);
      return square;
    }
  }
  // update trick
  ktm.updated = false;
  window.clearTimeout(params.tblState.updateTimeout);
  params.tblState.updateTimeout = window.setTimeout(() => {
    params.setTableData([...params.tableData]);
    params.setData([...params.data]);
  }, 200);
}

interface LegendClickHolder {
  timeout: number;
  mouseDownTime: number;
  numClicks: number;
}

export const GraphTable: FC<GraphTableProps> = ({ graphPlotData, data, setData, tableData, setTableData, state, setPlotVisible,
  graphProps: { box, common = {} as CommonGraphProps }, assignTickFormat, ...props }) => {
  const [gs] = useState(() => new GridScope<KTM>("graphTable"));
  const [tblState] = useState({ updateTimeout: 0 });
  const regroupedProps = useContext(RegroupContext);
  const firstHide = useRef(true);

  useEffect(() => {
    return () => {
      window.clearTimeout(tblState.updateTimeout);
    }
  }, []);

  useEffect(() => {
    hideItems();
  }, [regroupedProps?.hiddenItems]);

  useEffect(() => {
    if (tableData?.length && firstHide.current) {
      firstHide.current = false;
      hideItems();
    }
  }, [tableData]);

  function hideItems() {
    const items = regroupedProps?.hiddenItems;
    if (items?.length) {
      for (const pd of data) {
        if (!pd.visible) continue;
        pd.visible = true;
        for (const mu of items) {
          const matcher = GraphPlotUtil.traceMatcher(mu);
          if (matcher(pd)) {
            pd.visible = 'legendonly';
            break;
          }
        }
      }
      assignFixedUIDs();
      assignTickFormat(tableData);
      setData([...data]);
      setTableData([...tableData]);
    }
  }

  const preRendered = (path: readonly (string | number)[]) => (value, record: TimeseriesMetadata & IGridEntry) =>
    getPathValue(record.rendered, path) ?? getPathValue(record.rendered, [GraphPlotUtil.SINGLE_ITEM, ...path.slice(1)]);

  const onGridChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>,
    sorter: SorterResult<KTM> | SorterResult<KTM>[], extra: TableCurrentDataSource<KTM>) => {
    if (extra.action === 'filter') {
      for (const pd of data) {
        pd.visible = 'legendonly';
        for (const ktm of extra.currentDataSource) {
          if (ktm.name === pd.name) {
            pd.visible = true;
            break;
          }
        }
      }
      setData([...data]);
    } else if (extra.action === 'sort') {
      sortOrder = (sorter as SorterResult<KTM>).order;
    }
  }

  function isOthersActive() {
    return GraphPlotUtil.isOthersAllowed(box) && state.loadedItems && state.loadedItems < box.uuids.length;
  }

  function assignFixedUIDs() {
    if (isOthersActive() && data.some(pd => pd.visible !== true)) {
      state.uuids = data.filter(pd => pd.visible === true).map(pd => pd.meta.uid);
    } else {
      state.uuids = null;
    }
  }

  function tableSummaryData(currentData: (KTM & IGridEntry)[]) {
    // workaround to get sorted data
    if (!state.tableRowKeys.length) {
      state.tableRowKeys = currentData.map(ktm => ktm.key);
      return null;
    }
    let j = 0;
    for (const currentRow of currentData) {
      let found = false;
      for (; j < state.tableRowKeys.length; j++) {
        if (currentRow.key === state.tableRowKeys[j]) {
          found = true;
          break;
        }
      }
      if (!found) {
        state.tableRowKeys = currentData.map(ktm => ktm.key);
        GraphPlotUtil.sortPlotByTable(data, currentData);
        setTimeout(() => {
          setData([...data]);
        });
        break;
      }
    }
    return null;
  }

  function tableFooter() {
    const loaded = Math.min(state.loadedItems, box.uuids.length);
    // footer is present if assigned a function even it returns undefined
    return box.uuids.length === loaded || tableData.some(ktm => ktm.isOthers()) ? undefined
      : () => `Showing top ${loaded} items of ${box.uuids.length}`;
  }

  const propertyColumn = useCallback(propertyColumnMake(gs, common), [gs]);
  const colorParams: ColorSquareParams = {
    data, tableData, metricsCount: box.metrics.length, plotlyRef: state.plotlyRef, tblState, regroupedProps,
    setPlotVisible, setData, setTableData, assignTickFormat, assignFixedUIDs
  };

  return <gs.Grid
    key='graph-grid'
    dataSource={tableData}
    onChange={onGridChange}
    scroll={{ y: 90 }}
    pagination={false}
    bordered={true}
    summary={tableSummaryData}
    footer={tableFooter()}
    rowClassName={(record) => record.props?.separator ? 'xm-tr-separator' : ''}
  >
    {[
      <gs.Col
        key={'name'}
        className="xm-td-ellipsis"
        title={`${graphPlotData.isMetricItems() ? UnitUtil.formatUnitColHeader('Metric', state.unitSize)
          : graphPlotData.isMixedMetricItem() ? UnitUtil.formatUnitColHeader(box.label || "Name", state.unitSize) : (box.label || "Name")}`}
        dataIndex="name"
        sorter={stringSort(t => t.name, graphPlotData)}
        filter={{ field: ['name'] }}
        render={(value, record) => {
          const allMetrics = [...box.metrics, ...(common.availableMetrics || [])];
          if (state.peakMetric) allMetrics.push(box.peakMetric);
          const name = allMetrics.find(m => m.metric === value)?.label || value;
          const url = record.data[graphPlotData.isSingleItem() ? GraphPlotUtil.SINGLE_ITEM : box.metrics[0].metric]?.url;
          if (!box.aggregated || !url)
            if (record.isOthers())
              return <div className='xm-ellipsis' title={state.othersTooltip}>
                {`${name} (${state.othersCount} of ${box.uuids.length})`}
              </div>;
            else
              return <div className='xm-ellipsis'><div>{name}</div></div>;

          return <div className='xm-ellipsis'><LinkXM to={GLOB.menuService.getRouteLink(url, common?.tabName)}>{name}</LinkXM></div>;
        }}
      />,
      box.parent && !graphPlotData.isMetricItems() && <gs.Col key="parent"
        className='xm-td-ellipsis'
        title={box.parent.label}
        dataIndex={["parent", 'label']}
        sorter={stringSort(t => t.parent.label, graphPlotData)}
        filter={{ field: ['parent', 'label'] }}
        render={(value, record) => {
          if (!record.parent) return null;
          const label = record.parent.label;
          const url = record.parent.url;
          if (url)
            return <div className='xm-ellipsis'><LinkXM to={GLOB.menuService.getRouteLink(url, common.tabName)}>{label}</LinkXM></div>;
          return <div className='xm-ellipsis'><div>{label}</div></div>;
        }}
      />,
      ...(box.properties?.map(propertyColumn) || []),
      ...(graphPlotData.isSingleItem() ?
        [!graphPlotData.isMetricItems() && box.metrics.length > GraphPlotUtil.MAX_GROUP_METRICS && !graphPlotData.isMixedMetricItem() ? <gs.Col key="metric"
          className="xm-td-ellipsis" title={UnitUtil.formatUnitColHeader('Metric', state.unitSize)} dataIndex="metric"
          sorter={stringSort(t => t.metric, graphPlotData)}
          filter={{ field: ['metric'] }} />
          : null,
        <gs.Col key={'metriColor'} className='square-td' render={(value, record) => {
          return colorSquare(record, undefined, colorParams);
        }}></gs.Col>].concat(cols.map((c) => {
          const m = GraphPlotUtil.SINGLE_ITEM;
          return (
            <gs.Col
              {...c}
              key={c.key}
              width={60}
              minWidth={'5em'}
              dataIndex={['data', m, c.key]}
              sorter={numericSort(m, c.key, graphPlotData)}
              sortDirections={['descend', 'ascend']}
              filter={{ field: ['rendered', m, c.key], type: 'number' }}
              render={preRendered([m, c.key])}
            ></gs.Col>
          );
        }))
        : box.metrics.filter(m => !graphPlotData.isMetricItems(m)).map((m) => (
          <ColumnGroup key={m.metric} title={UnitUtil.formatUnitColHeader(m.label, state.unitSize)}>
            {[<gs.Col key={m.metric + 'Color'} className='square-td' render={(value, record) => {
              return colorSquare(record, record.rendered[GraphPlotUtil.SINGLE_ITEM] ? GraphPlotUtil.SINGLE_ITEM : m.metric, colorParams);
            }}></gs.Col>].concat(cols.map((c) => (
              <gs.Col
                {...c}
                width={60}
                minWidth={'5em'}
                key={m.metric + c.key}
                dataIndex={['data', m.metric, c.key]}
                sorter={numericSort(m.metric, c.key, graphPlotData)}
                sortDirections={['descend', 'ascend']}
                filter={{ field: ['rendered', m.metric, c.key], type: 'number' }}
                render={preRendered([m.metric, c.key])}
              ></gs.Col>
            )))}
          </ColumnGroup>
        ))),

    ].filter(c => c)}
  </gs.Grid>;
}

function propertyColumnMake(gs: GridScope<KTM>, common: CommonGraphProps) {
  return function propertyColumn(gtp: GraphTableProperty) {
    return createElement(gs.Col, {
      className: "xm-col-prop",
      key: gtp.label,
      dataIndex: ['props', gtp.label, 'value'],
      title: gtp.label,
      sorter: (a, b) => gtp.values[a.uid].value.localeCompare(gtp.values[b.uid].value),
      filter: gtp.search ? { field: ['props', gtp.label, 'value'] } : undefined,
      render: (value: string, record) => {
        if (!value) return;
        const item = record.props[gtp.label];
        const text = TextUtil.trimMiddle(item.value, 24);
        return item.url ? <LinkXM to={GLOB.menuService.getRouteLink(item.url, common?.tabName)}>{text}</LinkXM> : text;
      }
    });
  }
}