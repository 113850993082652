import { useContext } from 'react';
import { GLOB } from 'src/util/Glob';
import { RuleContext } from '../Rule';
import { EnableItemId } from '../RulesList';
import { AllItemsContext } from '../comp/AllItems';
import TagsList from '../comp/TagsList';

interface Props {
  onClose: (id: string) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
};

function TagsBox({ onClose }: Props) {
  const { allItems, isLoadingAll } = useContext(AllItemsContext);
  const { items } = useContext(RuleContext);

  function makeTreeObj({ disabled, item_id: key }: EnableItemId) {
    let title: string;
    allItems.some(item => {
      if (item.item_id === key) {
        title = item.label;
        return true;
      }

      return false;
    });

    return { title, key, disabled };
  }

  return (
    <>
      {!isLoadingAll &&
        <TagsList
          items={items.map(makeTreeObj).sort((a, b) => GLOB.naturalSort(a.title, b.title))}
          onClose={onClose}
        />
      }
    </>
  );
}

export default TagsBox;